import {History} from 'history'
import {combineReducers} from 'redux'
import {routerReducer, RouterState} from 'react-router-redux'

import {
  authenticationReducer,
  AuthenticationReducerType,
} from '../redux/authentication/authenticationReducer'

export interface RootState {
  loginForm: AuthenticationReducerType
  routerReducer: RouterState
}

export default (history: History) =>
  combineReducers({
    loginForm: authenticationReducer,
    routerReducer,
  })
