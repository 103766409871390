import React from 'react'
import {Horizontal} from './Horizontal'

const EditRegulatoryQuery = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-between'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-between'>
              Edit RQ: 11
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fw-bold fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Regulatory Query Dashboard
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted fw-bold'>RQ: 11</li>
            </ol>
          </div>
        </div>
      </div>

      <Horizontal />
    </div>
  )
}

export default EditRegulatoryQuery
