/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ProjectList from './ProjectList'

const DashboardWrapper: FC = () => {
  return (
    <>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Projects
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <a href='#' className='btn btn-sm fw-bold btn-primary'>
              Active
            </a>
            <div className='btn fw-bold btn-dark d-flex align-items-center justify-content-center w-30px h-35px'>
              <KTSVG path='/media/icons/duotune/layouts/lay002.svg' className='svg-icon-1 m-0' />
            </div>
          </div>
        </div>
      </div>
      <ProjectList />
    </>
  )
}

export default DashboardWrapper
