/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Accordion from '../../components/accordion/Accordion'
import Datatable from '../../components/datatable/Datatable'
import FileDrop from '../../components/fileDrop/FileDrop'
import Modal from '../../components/modal/Modal'

type Props = {}

const RegulatoryQueryDashboard: FC<Props> = () => {
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-between'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-between'>
              Regulatory Querys
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fw-bold fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted fw-bold'>Regulatory Querys</li>
            </ol>
          </div>
        </div>
      </div>

      <div className='col-md-12 mt-5'>
        <div className='card'>
          <div className='card-header cursor-pointer align-items-center'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Regulatory Queries</h3>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='col-lg-4 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Person Responsible:
                </label>
                <select className='form-control datatable-input' data-col-index='2'>
                  <option value=''>Select</option>
                  <option value='BP Demo guy'>BP Demo guy</option>
                  <option value='Blair MacDougall'>Blair MacDougall</option>
                  <option value='Phil Nash'>Phil Nash</option>
                </select>
              </div>
              <div className='col-lg-4 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Priority:</label>
                <select className='form-control datatable-input' data-col-index='5'>
                  <option value=''>Select</option>
                  <option value='High'>High</option>
                  <option value='Low'>Low</option>
                  <option value='Medium'>Medium</option>
                </select>
              </div>
              <div className='col-lg-4 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Approval Status:
                </label>
                <select className='form-control datatable-input' data-col-index='6'>
                  <option value=''>Select</option>
                  <option value='No'>No</option>
                  <option value='Yes'>Yes</option>
                </select>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-sm btn-light btn-active-light-primary me-5'
                data-kt-menu-dismiss='true'
              >
                Reset
              </button>
              <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-12 mt-5'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body'>
            <div className='mt-5'>
              <Datatable
                columns={[
                  {title: 'ID	'},
                  {title: 'Subject	'},
                  {title: 'Priority	'},
                  {title: 'Person Responsible	'},
                  {title: 'Approval Status'},
                  {title: 'Action Items'},
                  {title: 'Bulk Import Action Items'},
                  {title: 'Files'},
                  {title: 'Actions'},
                ]}
                rows={
                  <>
                    <tr>
                      <td> RQ: 11 </td>
                      <td> Deviation From Installation Regulations Doors 5.1 </td>
                      <td>
                        <div className='badge badge-light-danger'>High </div>
                      </td>
                      <td> BP Demo guy </td>
                      <td>
                        <div className='badge badge-light-warning'>In Progress </div>
                      </td>
                      <td> </td>
                      <td></td>
                      <td>
                        <div
                          className='d-flex justify-content-end flex-shrink-0 cursor-pointer'
                          onClick={() => setIsFileModalOpen(true)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil012.svg'
                              className='mh-50px me-2'
                            />
                          </span>
                          Files(0)
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to='/vessel-intake/regulatory-query-details/10'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <Link
                            to='/vessel-intake/regulatory-query/edit/10'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={isFileModalOpen}
        handleClose={() => setIsFileModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>Regulatory Queries Files</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsFileModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          <Accordion
            title={'Uploaded files'}
            body={
              <div className='d-flex justify-content-center mt-5'>
                <div className='alert alert-warning'>No Files Found!</div>
              </div>
            }
            textColor={'primary'}
            isActive={false}
          />
        </div>
        <div className='bg-light-primary text-primary p-5 fw-bold text-center'>
          Upload a New File
        </div>
        <div className='modal-body px-8'>
          <div className='col-lg-12 mb-6'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              Description <span className='text-danger ms-1'>*</span>
            </label>
            <input className='form-control' />
          </div>
          <div className='col-lg-12 mb-6'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              File <span className='text-danger ms-1'>*</span>
            </label>
            <FileDrop />
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center py-5'>
          <button type='submit' className='btn btn-primary mx-2'>
            <i className='bi bi-check2-circle'></i> Save
          </button>
          <button
            type='submit'
            className='btn btn-light mx-2'
            onClick={() => setIsFileModalOpen(false)}
          >
            <i className='bi bi-x'></i>Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default RegulatoryQueryDashboard
