import React, {FC} from 'react'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='form-group col-md-4 mb-3'>
              <label className='col-md-12 col-form-label' htmlFor='RQ.ApprovalStatus'>
                Approval Status<span className='text-danger ml-1'>*</span>
              </label>
              <div className='col-md-12'>
                <select
                  className='form-control'
                  id='RQV_ApprovalStatus'
                  name='RQV.ApprovalStatus'
                  required
                >
                  <option value=''>-- Select One --</option>
                  <option>Approved Without Conditions</option>
                  <option>Approved With Conditions</option>
                  <option>Rejected</option>
                  <option>Submitted</option>
                  <option>CA Sign Off</option>
                  <option>Operator Sign Off</option>
                  <option>DC Sign Off</option>
                  <option>Draft Complete</option>
                  <option selected>In Progress</option>
                  <option>Not Started</option>
                </select>
              </div>
            </div>

            <div className='form-group col-md-4 mb-3'>
              <label className='col-md-12 col-form-label' htmlFor='RQ.Priority'>
                Priority<span className='text-danger ml-1'>*</span>
              </label>
              <div className='col-md-12'>
                <select className='form-control' id='RQV_Priority' name='RQV.Priority' required>
                  <option value=''>-- Select One --</option>
                  <option selected>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>
            </div>

            <div className='form-group col-md-4 mb-3'>
              <label className='col-md-12 col-form-label' htmlFor='RQ.PersonResponsibleId'>
                Person Responsible<span className='text-danger ml-1'>*</span>
              </label>
              <div className='col-md-12'>
                <select
                  className='form-control'
                  id='RQV_PersonResponsibleId'
                  name='RQV.PersonResponsibleId'
                  required
                >
                  <option value=''>-- Select One --</option>
                  <option value='1732c937-964d-4a56-b0ca-16881461590f'>Blair MacDougall</option>
                  <option value='369a0725-9088-4f49-b538-ba8f1ebc7762'></option>
                  <option value='3d2ec324-8a37-47d0-ac8c-3ce765ff9532'>Udit Gandhi</option>
                  <option selected value='4392365e-2f8c-4325-8592-041f9e6d77cf'>
                    BP Demo guy
                  </option>
                  <option value='49d47c92-7bf9-440f-a385-406349fa8bf6'>Vinay</option>
                  <option value='4af0dfbe-0e71-4212-bd8c-6468988be954'>Pradeep</option>
                  <option value='537e1a46-aae3-4b76-91f5-75f02f557ed6'>Phil Nash</option>
                  <option value='e77f12da-3932-42c1-a824-b9425b841c36'>Raghav</option>
                </select>
              </div>
            </div>
          </div>
          <div className='text-center mt-5'>
            <div className='mb-3'>
              <div className='custom-control custom-checkbox'>
                <label className='form-check form-check-sm form-check-solid'>
                  <input
                    className='form-check-input mt-1'
                    type='checkbox'
                    value='2'
                    checked={true}
                  />
                  <span className='form-check-label'>
                    <label className='d-flex align-items-center fs-5 pt-1' htmlFor='Boilerplate'>
                      I have reviewed the imported information and affirm that information is
                      accurate <span className='text-danger'>*</span>
                    </label>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
