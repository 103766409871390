export interface Todo {
  id: number
  text: string
  completed: boolean
}

export interface Listing {
  city: string
  postId: string
  title: string
  contentSnippet: string
  link: string
  viewed: boolean
  emailed: boolean
  postedTimestamp: number
  createdTimestamp: number
}

export enum ActionType {
  // login
  LOGIN_USER = 'action/LOGIN_USER',
  LOGIN_USER_SUCCESS = 'action/LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'action/LOGIN_USER_ERROR',
}

export interface Action<T> {
  type: ActionType
  payload: T
}

export interface LoginData {
  email: string | null
  password: string | null
}
