/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='col-md-6 pe-5'>
          <div className='form-group row mb-3' id='rqType'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.Type'>
              Select type<span className='text-danger ml-1'>*</span>
            </label>
            <div className='col-md-12'>
              <select className='form-control' id='RQ_Type' name='RQ.Type' required>
                <option value=''>-- Select Type--</option>
                <option>CNSOPB</option>
                <option>CNLOPB</option>
              </select>
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.Subject'>
              Subject<span className='text-danger ml-1'>*</span>
            </label>
            <div className='col-md-12'>
              <input
                className='form-control'
                id='RQV_Subject'
                name='RQV.Subject'
                placeholder='Add subject'
                required
                type='text'
                value='Deviation From Installation Regulations Doors 5.1'
              />
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.Regulation'>
              Regulation<span className='text-danger ml-1'>*</span>
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_Regulation'
                name='RQV.Regulation'
                placeholder='Add regulation'
                required
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.Date'>
              Due Date<span className='text-danger ml-1'>*</span>
            </label>
            <div className='col-md-12'>
              <input
                className='form-control flatpickr-input'
                data-toggle='flatpicker'
                id='RQV_Date'
                name='RQV.Date'
                required
                type='hidden'
                value='03/28/2023'
              />
              <input
                className='form-control form-control input'
                placeholder=''
                required
                tabIndex={0}
                type='text'
                readOnly={true}
              />
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.FileNumber'>
              File Number<span className='text-danger ml-1'>*</span>
            </label>
            <div className='col-md-12'>
              <input
                className='form-control'
                id='RQV_FileNumber'
                name='RQV.FileNumber'
                placeholder='Add file number'
                required
                type='text'
                value='11'
              />
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.SOR'>
              SOR
            </label>
            <div className='col-md-12'>
              <input
                className='form-control'
                id='RQV_SOR'
                name='RQV.SOR'
                placeholder='Add SOR'
                type='text'
                value=''
              />
            </div>
          </div>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQ.Section'>
              Section
            </label>
            <div className='col-md-12'>
              <input
                className='form-control'
                id='RQV_Section'
                name='RQV.Section'
                placeholder='Add Section'
                type='text'
                value=''
              />
            </div>
          </div>
          <div className='form-group row mb-2' id='requesting' style={{display: 'none'}}>
            <label className='col-md-12 col-form-label mr-1 pt-2'>Requesting</label>
            <div className='col-md-12 display_contents'>
              <div className='pt-2 display_flex pl-2'>
                <div className='custom-control custom-checkbox mb-2 mr-2'>
                  <input
                    className='custom-control-input'
                    id='RQV_IsDeviation'
                    name='RQV.IsDeviation'
                    type='checkbox'
                    value='true'
                  />
                  <input name='RQV.IsDeviation' type='hidden' value='false' />
                  <label className='custom-control-label' htmlFor='RQV_IsDeviation'>
                    Deviation
                  </label>
                </div>
                <div className='custom-control custom-checkbox mb-2'>
                  <input
                    className='custom-control-input'
                    id='RQV_IsExemption'
                    name='RQV.IsExemption'
                    type='checkbox'
                    value='true'
                  />
                  <input name='RQV.IsExemption' type='hidden' value='false' />
                  <label className='custom-control-label' htmlFor='RQV_IsExemption'>
                    Exemption
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 ps-5'>
          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.Proposal'>
              {' '}
              Proposed Deviation
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_Proposal'
                name='RQV.Proposal'
                placeholder='Add Proposed Deviation'
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3' id='rqquery' style={{display: 'none'}}>
            <label className='col-md-12 col-form-label' htmlFor='RQV.Query'>
              Query
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_Query'
                name='RQV.Query'
                placeholder='Add Query'
                rows={2}
              ></textarea>
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.Rationale'>
              Rationale
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_Rationale'
                name='RQV.Rationale'
                placeholder='Add Rationale'
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3' id='rationalehazardrisk'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.RationaleHzd'>
              Hazard and associated risk mitigated by the regulation
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_RationaleHzd'
                name='RQV.RationaleHzd'
                placeholder='Add RationaleHzd'
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3' id='rationalemeasurerisk'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.RationaleMeasure'>
              Measures to mitigate the risk
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_RationaleMeasure'
                name='RQV.RationaleMeasure'
                placeholder='Add RationaleMeasure'
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3' id='rationalediscussion'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.RationaleDiscussion'>
              Discussion on how the proposed deviation reduces risk to ALARP
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_RationaleDiscussion'
                name='RQV.RationaleDiscussion'
                placeholder='Add RationaleDiscussion'
                rows={2}
              ></textarea>
            </div>
          </div>
          <div className='form-group row mb-3' id='rationaleprecedence'>
            <label className='col-md-12 col-form-label' htmlFor='RQV.RationalePrecedence'>
              Precedence, if any
            </label>
            <div className='col-md-12'>
              <textarea
                className='form-control'
                cols={20}
                id='RQV_RationalePrecedence'
                name='RQV.RationalePrecedence'
                placeholder='Add RationalePrecedence'
                rows={2}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
