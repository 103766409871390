import React, {FC} from 'react'
import {Link} from 'react-router-dom'

const VesselDetail: FC = () => {
  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Demo Vessel 7</h3>
          </div>
          <Link
            className='btn btn-primary align-self-center'
            to='/vessel/edit/:projectId/:vesselId'
          >
            Edit Vessel
          </Link>
        </div>
        <div className='card-body p-9'>
          <div className='row'>
            <div className='col'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>IMO Number</label>
                <div className='col-lg-8 fv-row'>
                  <span className='fw-bold fs-6'>5465</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Description</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus minima vitae
                    doloribus incidunt molestias autem ipsam, accusamus labore inventore quas quam
                    nobis nam eligendi reiciendis distinctio animi est officiis.
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Water Depth</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>10000</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Year Built</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>2010</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Status</label>
                <div className='col-lg-8'>
                  <span className='badge badge-light-success'>Ready to Tow</span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Contract DayRate</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>NA</span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Owner</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Maersk</span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Flag State</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Barbados</span>
                </div>
              </div>
              <div className='row mb-10'>
                <label className='col-lg-4 fw-bold text-muted'>Vessel Type</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6'>Semi-Submersible</span>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='row p-0'>
                <div className='rounded my-2 border-secondary border border-dashed px-5 py-4'>
                  <img
                    className='w-100'
                    src='http://wesiwassuredev.us-west-2.elasticbeanstalk.com/MContent/images/AddVesselDefault.png'
                  />
                </div>

                <div className='notice my-2 d-flex bg-light-primary rounded border-primary border border-dashed px-5 py-4 me-5'>
                  <span className='svg-icon svg-icon-2tx svg-icon-primary'></span>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Arrival Date</h4>
                      <div className='fs-6 text-primary'>9/30/2022</div>
                    </div>
                  </div>
                </div>
                <div className='notice my-2 d-flex bg-light-success rounded border-success border border-dashed px-5 py-4 me-5'>
                  <span className='svg-icon svg-icon-2tx svg-icon-success'></span>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Estimated Start Date</h4>
                      <div className='fs-6 text-success'>10/07/2022</div>
                    </div>
                  </div>
                </div>
                <div className='notice my-2 d-flex bg-light-danger rounded border-danger border border-dashed px-5 py-4 me-5'>
                  <span className='svg-icon svg-icon-2tx svg-icon-danger'></span>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Estimated End Date</h4>
                      <div className='fs-6 text-danger'>12/02/2022</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VesselDetail
