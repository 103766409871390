import React, {FC} from 'react'
import {SearchInput} from '../searchInput/SearchInput'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

interface ColumnState {
  title: string
}
interface DatatableState {
  columns: Array<ColumnState>
  rows: any
  search?: boolean
}

const Datatable: FC<DatatableState> = ({columns, rows, search = true}) => {
  return (
    <div>
      {search && (
        <div className='d-flex justify-content-end mb-2'>
          <SearchInput />
        </div>
      )}

      <div>
        <table className='table table-hover table-rounded table-striped border gy-5 gs-5'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              {columns.map((col: ColumnState) => (
                <th>{col.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  )
}

export default Datatable
