/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'

const ProjectDetail = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Project Details
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>Dev team Testing</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='d-flex flex-column'>
                  <div className='fs-3 fw-bolder text-dark text-hover-primary cursor-pointer'>
                    Dev team Testing
                  </div>
                </div>
              </div>
              <div className='m-0'>
                <a
                  href='#'
                  className={'btn btn-icon'}
                  data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement={'bottom-end'}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen052.svg'
                    className={'theme-dark-hide'}
                  />
                </a>
                <div
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                  data-kt-menu='true'
                >
                  <div className='menu-item px-3 my-0'>
                    <Link to='/project/edit/2' className={clsx('menu-link px-3 py-2 light')}>
                      <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </span>
                      <span className='menu-title'>Edit Project</span>
                    </Link>
                  </div>
                  <div className='menu-item px-3 my-0'>
                    <Link
                      to='/project/invite-users/2'
                      className={clsx('menu-link px-3 py-2 light')}
                    >
                      <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen049.svg'
                          className='svg-icon-3'
                        />
                      </span>
                      <span className='menu-title'>Invite User</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='bg-light-primary rounded border-primary border border-solid p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Project Manager
                  </a>
                  <span className='text-primary fw-semibold d-block'>RaghavGupta@dal.ca</span>
                </div>
              </div>
              <div className='bg-light-success rounded border-success border border-solid p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Project Overview:
                  </a>
                  <span className='text-success fw-semibold d-block'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, eligendi.
                  </span>
                </div>
              </div>
            </div>
            <div className='separator my-4'></div>
            <div className='d-flex mt-5'>
              <div className='bg-light-success rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Start Date
                  </a>
                  <span className='text-success fw-semibold d-block'>26 Aug 2020</span>
                </div>
              </div>
              <div className='bg-light-warning rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Spud Date
                  </a>
                  <span className='text-warning fw-semibold d-block'>31 Oct 2020</span>
                </div>
              </div>

              <div className='bg-light-danger rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Target Closure Date
                  </a>
                  <span className='text-danger fw-semibold d-block'>31 Dec 2020</span>
                </div>
              </div>
              <div className='bg-light-info rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Actual End Date
                  </a>
                  <span className='text-info fw-semibold d-block'>31 Dec 2020</span>
                </div>
              </div>
            </div>

            <div className='d-flex mt-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4'>
                <div className='fs-6 text-gray-800 fw-bolder'>Project Quick Links</div>
                <div className='d-flex my-2'>
                  <Link
                    to='/project/regulatory-dashboard/2'
                    className='btn btn-sm btn-primary text-white px-4 py-2 me-4'
                  >
                    Authorizations/Approvals Dashboard
                  </Link>
                  <Link
                    to='/project/action-dashboard/2'
                    className='btn btn-sm btn-success text-white px-4 py-2 me-4'
                  >
                    All Action Items Register
                  </Link>
                  <Link
                    to='/project/bulk-import/2'
                    className='btn btn-sm btn-warning text-white px-4 py-2 me-4'
                  >
                    Bulk Import General Actions
                  </Link>
                  <Link
                    to='/project/custom-checklists/2'
                    className='btn btn-sm btn-info text-white px-4 py-2'
                  >
                    Project Checklists
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-12 mt-5'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-between flex-grow-1'>
              <div className='d-flex'>
                <div className='fs-3 fw-bolder text-dark text-hover-primary cursor-pointer'>
                  Vessels / Installations
                </div>
                <div className='d-flex ms-5'>
                  <Link
                    to='/vessel/add/1'
                    className='btn btn-sm btn-primary text-white px-4 py-2 me-4'
                  >
                    Add New Vessel
                  </Link>
                  <Link
                    to='/regulatory-dashboard/2'
                    className='btn btn-sm btn-success text-white px-4 py-2 me-4'
                  >
                    Installation Shortlist Dashboard
                  </Link>
                </div>
              </div>
              <div className='my-0'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  <span className='svg-icon svg-icon-2'>
                    <i className='bi bi-dash-lg'></i>
                  </span>
                </button>
              </div>
            </div>
            <div className='mt-10'>
              <Datatable
                columns={[
                  {title: 'IMO Number'},
                  {title: 'Vessel Name'},
                  {title: 'Vessel Type'},
                  {title: 'Modules'},
                  {title: 'Arrival Date'},
                  {title: 'Flag State'},
                  {title: 'Owner'},
                  {title: 'Actions'},
                ]}
                rows={
                  <>
                    <tr>
                      <td> 1524 </td>
                      <td> Demo 7 </td>
                      <td> Submersible </td>
                      <td>
                        <Link
                          to='/vessel-intake/dashboard/5'
                          className='btn btn-sm btn-light-warning py-1'
                        >
                          Vessel Intake
                        </Link>
                      </td>
                      <td>
                        <div className='badge badge-light-primary'> 9/30/2022 </div>
                      </td>
                      <td> Barbados </td>
                      <td> demo </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to='/vessel/1'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <Link
                            to='/vessel/edit/:projectId/:vesselId'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td> 5465 </td>
                      <td> Demo Vessel 7 </td>
                      <td> Semi-Submersible </td>
                      <td>
                        <Link
                          to='/vessel-intake/dashboard/5'
                          className='btn btn-sm btn-light-warning py-1'
                        >
                          Vessel Intake
                        </Link>
                      </td>
                      <td>
                        <div className='badge badge-light-primary'> 9/30/2022 </div>
                      </td>
                      <td> Barbados </td>
                      <td> Maersk </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to='/vessel/1'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <Link
                            to='/vessel/edit/:projectId/:vesselId'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetail
