import {useState, useRef, Children} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  dialogClassName: string
  show: boolean
  children: any
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalMain = ({show, dialogClassName, children, handleClose}: Props) => {
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={dialogClassName}
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      {children}
    </Modal>,
    modalsRoot
  )
}

export default ModalMain
