import {createBrowserHistory} from 'history'
import * as localforage from 'localforage'
import {configureStore} from '@reduxjs/toolkit'
import {createLogger} from 'redux-logger'
import saga from 'redux-saga'
import {PersistConfig, persistReducer, persistStore} from 'redux-persist'
// config
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = saga()

const persistConfig: PersistConfig<any, any, any, any> = {
  key: 'root',
  version: 1,
  storage: localforage,
  blacklist: ['loginForm'],
}

const logger = (createLogger as any)()
const history = createBrowserHistory()

const persistedReducer = persistReducer(persistConfig, rootReducer(history))

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [sagaMiddleware, logger],
    devTools: process.env.NODE_ENV !== 'production',
  })
  const persistor = persistStore(store)
  return {
    store,
    persistor,
    runSaga: sagaMiddleware.run(rootSaga),
  }
}

export {history}
