/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Accordion from '../../components/accordion/Accordion'
import Modal from '../../components/modal/Modal'

type Props = {}

const RegulatoryDashboard: FC<Props> = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const [isAppModalOpen, setIsAppModalOpen] = useState(false)
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Regulatory Dashboard
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>
                BP Deepwater Exploration 2023 Demo Jan 2022
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between'>
                  <div className='symbol symbol-50px'>
                    <span className='symbol-label bg-primary'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs039.svg'
                        className='svg-icon-muted svg-icon-2hx text-white'
                      />
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <div className='text-primary fs-2 fw-bolder'>7</div>
                    <span className='fw-bold d-block'>Authorization Deliverables Count</span>
                  </div>
                </div>
                <div className='d-flex mt-5'>
                  <div className='d-flex flex-column w-100'>
                    <div className='d-flex justify-content-between mb-2'>
                      <span className='text-muted fs-7 fw-semibold'>COMPLETE</span>
                      <span className='text-muted fs-7 fw-semibold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '70%'}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between'>
                  <div className='symbol symbol-50px'>
                    <span className='symbol-label bg-warning'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra001.svg'
                        className='svg-icon-muted svg-icon-2hx text-white'
                      />
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <div className='text-warning fs-2 fw-bolder'>1</div>
                    <span className='fw-bold d-block'>Approval Deliverables Count</span>
                  </div>
                </div>
                <div className='d-flex mt-5'>
                  <div className='d-flex flex-column w-100'>
                    <div className='d-flex justify-content-between mb-2'>
                      <span className='text-muted fs-7 fw-semibold'>COMPLETE</span>
                      <span className='text-muted fs-7 fw-semibold'>20%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{width: '20%'}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between'>
                  <div className='symbol symbol-50px'>
                    <span className='symbol-label bg-info'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra006.svg'
                        className='svg-icon-muted svg-icon-2hx text-white'
                      />
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <div className='text-info fs-2 fw-bolder'>10</div>
                    <span className='fw-bold d-block'>Regulatory Deliverables Count</span>
                  </div>
                </div>
                <div className='d-flex mt-5'>
                  <div className='d-flex flex-column w-100'>
                    <div className='d-flex justify-content-between mb-2'>
                      <span className='text-muted fs-7 fw-semibold'>COMPLETE</span>
                      <span className='text-muted fs-7 fw-semibold'>100%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{width: '100%'}}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-12 mt-5'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-header cursor-pointer align-items-center'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Authorizations</h3>
                </div>
                <div
                  className='btn btn-primary d-flex align-items-center'
                  onClick={() => setIsAuthModalOpen(true)}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr013.svg'
                    className='svg-icon-muted svg-icon-1hx'
                  />
                  <span>Add New</span>
                </div>
              </div>
              <div className='card-body'>
                <Accordion
                  title={' Geophysical Program Authorization (GPA) '}
                  body={
                    <div className='d-flex justify-content-space-between mt-5'>
                      <div className='me-10 fw-bold text-muted'>
                        <i>
                          2023 Drilling Operations Authorization for BP Deepwater Exploration 2023
                          Demo Jan 2022
                        </i>
                        <div className={`badge badge-light-primary fw-bold ms-5`}>
                          <i>2 Requirements </i>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='mh-50px'
                            />
                          </span>
                        </Link>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                          </span>
                        </Link>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='mh-50px'
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  }
                  textColor={'primary'}
                />
                <Accordion
                  title={' Operations Authorization (OA) '}
                  body={
                    <>
                      <div className='d-flex justify-content-space-between mt-5'>
                        <div className='me-10 fw-bold text-muted'>
                          <i>2022 Seabed Survey for BP Deepwater Exploration 2023 Demo Jan 2022</i>
                          <div className={`badge badge-light-primary fw-bold ms-5`}>
                            <i>1 Requirements </i>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to=''
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <Link
                            to=''
                            className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </div>

                      <div className='d-flex justify-content-space-between mt-5'>
                        <div className='me-10 fw-bold text-muted'>
                          <i>Test 1 for BP Deepwater Exploration 2023 Demo Jan 2022</i>
                          <div className={`badge badge-light-primary fw-bold ms-5`}>
                            <i>2 Requirements </i>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to=''
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <Link
                            to=''
                            className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </Link>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                    </>
                  }
                  textColor={'primary'}
                />
                <Accordion
                  title={' Diving Program Autorization (DVPA) '}
                  body={
                    <div className='d-flex justify-content-space-between mt-5'>
                      <div className='me-10 fw-bold text-muted'>
                        <i>
                          2023 Drilling Survey for BP Deepwater Exploration 2023 Demo Sept 2022 for
                          BP Deepwater Exploration 2023 Demo Jan 2022
                        </i>
                        <div className={`badge badge-light-primary fw-bold ms-5`}>
                          <i>1 Requirements </i>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='mh-50px'
                            />
                          </span>
                        </Link>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                          </span>
                        </Link>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='mh-50px'
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  }
                  textColor={'primary'}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-header cursor-pointer align-items-center'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Approvals</h3>
                </div>
                <div
                  className='btn btn-info d-flex align-items-center'
                  onClick={() => setIsAppModalOpen(true)}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr013.svg'
                    className='svg-icon-muted svg-icon-1hx'
                  />
                  <span>Add New</span>
                </div>
              </div>
              <div className='card-body'>
                <Accordion
                  title={' Approval to Drill a Well (ADW) '}
                  body={
                    <div className='d-flex justify-content-space-between mt-5'>
                      <div className='me-10 fw-bold text-muted'>
                        <i>2023 ADW for Well for BP Deepwater Exploration 2023 Demo Jan 2022</i>
                        <div className={`badge badge-light-info fw-bold ms-5`}>
                          <i>1 Requirements </i>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='mh-50px'
                            />
                          </span>
                        </Link>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                          </span>
                        </Link>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='mh-50px'
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  }
                  textColor={'info'}
                />
                <Accordion
                  title={' Benefits Plan Approval '}
                  body={
                    <div className='d-flex justify-content-space-between mt-5'>
                      <div className='me-10 fw-bold text-muted'>
                        <i>
                          2022 and 2023 Deepwater Exploration Benefits Plan for BP Deepwater
                          Exploration 2023 Demo Jan 2022
                        </i>
                        <div className={`badge badge-light-info fw-bold ms-5`}>
                          <i>0 Requirements </i>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='mh-50px'
                            />
                          </span>
                        </Link>
                        <Link
                          to=''
                          className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                          </span>
                        </Link>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='mh-50px'
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  }
                  textColor={'info'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={isAuthModalOpen}
        handleClose={() => setIsAuthModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>
            Add New Authorization for BP Deepwater Exploration 2023 Demo Jan 2022
          </h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsAuthModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          <div className='row'>
            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Title</label>
              <input className='form-control' placeholder='Enter Title' />
            </div>
            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Authorization Type
              </label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value='No'>No</option>
                <option value='Yes'>Yes</option>
              </select>
            </div>
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center py-5'>
          <button type='submit' className='btn btn-primary mx-2'>
            <i className='bi bi-check2-circle'></i> Save
          </button>
          <button
            type='submit'
            className='btn btn-light mx-2'
            onClick={() => setIsAuthModalOpen(false)}
          >
            <i className='bi bi-x'></i>Close
          </button>
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={isAppModalOpen}
        handleClose={() => setIsAppModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>Add New Approval for BP Deepwater Exploration 2023 Demo Jan 2022</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsAppModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          <div className='row'>
            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Title</label>
              <input className='form-control' placeholder='Enter Title' />
            </div>
            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Operations Authorization
              </label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value='No'>No</option>
                <option value='Yes'>Yes</option>
              </select>
            </div>

            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Approval Type
              </label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value='No'>No</option>
                <option value='Yes'>Yes</option>
              </select>
            </div>
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center py-5'>
          <button type='submit' className='btn btn-info mx-2'>
            <i className='bi bi-check2-circle'></i> Save
          </button>
          <button
            type='submit'
            className='btn btn-light mx-2'
            onClick={() => setIsAppModalOpen(false)}
          >
            <i className='bi bi-x'></i>Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default RegulatoryDashboard
