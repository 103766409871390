/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'

type Props = {}

const BoilerPlateChecklists: FC<Props> = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Custom Project Checklists
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>Custom Project Checklists</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body'>
            <div className='d-flex align-items-center justify-content-between flex-grow-1'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='fs-3 fw-bolder text-dark text-hover-primary cursor-pointer'>
                  Checklists
                </div>
                <Link
                  to='/project/custom-checklists/2'
                  className='btn btn-sm btn-light-primary cursor-pointer ms-4'
                >
                  Custom Checklists
                </Link>
              </div>
              <div className='my-0'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  <span className='svg-icon svg-icon-2'>
                    <i className='bi bi-dash-lg'></i>
                  </span>
                </button>
              </div>
            </div>
            <div className='mt-10'>
              <Datatable
                search={false}
                columns={[
                  {title: 'Name	'},
                  {title: 'Short Code	'},
                  {title: 'Description	'},
                  {title: 'Options'},
                ]}
                rows={
                  <>
                    {' '}
                    <tr>
                      <td> Aymans, checklist, checkcomma </td>
                      <td> checklist </td>
                      <td> Testing adding additional sections to previously created checklist</td>
                      <td> </td>
                    </tr>{' '}
                    <tr>
                      <td> Drilling and Production </td>
                      <td> DPR </td>
                      <td> No Description Set</td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td> Example of Type Code </td>
                      <td> Code </td>
                      <td> Example</td>
                      <td> </td>
                    </tr>{' '}
                    <tr>
                      <td> Frontier and Offshore Regulatory Renewal Initiative</td>
                      <td> FORRI </td>
                      <td> No Description Set</td>
                      <td> </td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoilerPlateChecklists
