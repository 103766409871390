import {Action, ActionType, LoginData} from '../../model/model'
import createReducer from '../createReducer'

export interface AuthenticationReducerType {
  user: object
  loading: boolean
  error?: string
}
const defaultState: AuthenticationReducerType = {
  user: {},
  loading: false,
  error: undefined,
}

export const authenticationReducer = createReducer<AuthenticationReducerType>(defaultState, {
  [ActionType.LOGIN_USER](state: AuthenticationReducerType, action: Action<LoginData>) {
    return {
      ...state,
      loading: true,
    }
  },

  [ActionType.LOGIN_USER_ERROR](state: AuthenticationReducerType, action: Action<number>) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    }
  },

  [ActionType.LOGIN_USER_SUCCESS](state: AuthenticationReducerType, action: Action<number>) {
    return {
      ...state,
      loading: false,
      error: null,
      user: action.payload,
    }
  },
})
