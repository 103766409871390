import {useDropzone} from 'react-dropzone'

function FileDrop(props: any) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone()

  const files = acceptedFiles.map((file: any) => (
    <div
      className='bg-light-secondary rounded border-secondary border border-solid p-5 d-flex justify-content-between'
      key={file.path}
    >
      <span>
        {file.path} - {file.size} bytes
      </span>
      <span className='cursor-pointer'>
        <i className='bi bi-x'></i>
      </span>
    </div>
  ))

  return (
    <>
      <div {...getRootProps({className: 'dropzone h-100'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {files.length > 0 && (
        <aside className='mt-5'>
          <h3>Files</h3>
          <div>{files}</div>
        </aside>
      )}
    </>
  )
}

export default FileDrop
