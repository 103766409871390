import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import ActionDashboard from '../pages/actionDashboard/ActionDashboard'
import BulkImport from '../pages/bulkImport/BulkImport'
import BoilerPlateChecklists from '../pages/checklists/BoilerPlateChecklists'
import CustomChecklists from '../pages/checklists/CustomChecklists'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import EditRegulatoryQuery from '../pages/editRegulatoryQuery/EditRegulatoryQuery'
import EditUser from '../pages/inviteUsers/EditUser'
import InviteUsers from '../pages/inviteUsers/InviteUsers'
import EditProject from '../pages/projectDetail/EditProject'
import ProjectDetail from '../pages/projectDetail/ProjectDetail'
import RegulatoryDashboard from '../pages/regulatoryDashboard/RegulatoryDashboard'
import RegulatoryQueryDashboard from '../pages/regulatoryQueryDashboard/RegulatoryQueryDashboard'
import RegulatoryQueryDetails from '../pages/regulatoryQueryDetails/RegulatoryQueryDetails'
import AddVessel from '../pages/vessel/AddVessel'
import VesselDetail from '../pages/vessel/VesselDetail'
import VesselActionItems from '../pages/vesselActionItems/VesselActionItems'
import VesselIntakeDetails from '../pages/vesselIntakeDetails/VesselIntakeDetails'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='project/:projectId' element={<ProjectDetail />} />
        <Route path='project/edit/:projectId' element={<EditProject />} />
        <Route path='project/invite-users/:projectId' element={<InviteUsers />} />
        <Route path='project/edit-user/:userId' element={<EditUser />} />

        {/* Vessels */}
        <Route path='vessel/add/:projectId' element={<AddVessel />} />
        <Route path='vessel/edit/:projectId/:vesselId' element={<AddVessel />} />
        <Route path='vessel/:vesselId' element={<VesselDetail />} />
        <Route path='vessel-intake/dashboard/:vesselId' element={<VesselIntakeDetails />} />
        <Route
          path='vessel-intake/regulatory-query/:vesselId'
          element={<RegulatoryQueryDashboard />}
        />
        <Route
          path='vessel-intake/regulatory-query-details/:rqId'
          element={<RegulatoryQueryDetails />}
        />
        <Route
          path='vessel-intake/regulatory-query/edit/:rqId'
          element={<EditRegulatoryQuery />}
        />
        <Route path='vessel-intake/action-items/:vesselId' element={<VesselActionItems />} />

        <Route path='project/regulatory-dashboard/:projectId' element={<RegulatoryDashboard />} />
        <Route path='project/action-dashboard/:projectId' element={<ActionDashboard />} />
        <Route path='project/bulk-import/:projectId' element={<BulkImport />} />
        <Route path='project/custom-checklists/:projectId' element={<CustomChecklists />} />
        <Route
          path='project/boiler-plate-checklists/:projectId'
          element={<BoilerPlateChecklists />}
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
