import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

interface PropsState {
  title: string
  body: any
  textColor: string
  isActive?: boolean
}
const Accordion = ({title, body, textColor, isActive = true}: PropsState) => {
  const [active, setActive] = useState(isActive)

  return (
    <div
      onClick={() => setActive(!active)}
      className='notice cursor-pointer mb-3 rounded border-secondary border border-solid px-5 py-4 transition-normal'
    >
      <div className='d-flex align-items-center user-select-none'>
        <KTSVG
          path='/media/icons/duotune/arrows/arr024.svg'
          className={`svg-icon-muted svg-icon-1hx me-2  ${
            active ? `text-${textColor} rotate-0-90` : 'rotate-90-0'
          }`}
        />
        <div className={`fw-bold text-${active && textColor}`}>{title}</div>
      </div>
      {active && (
        <div className='fade-in'>
          <div className='separator my-4'></div>
          {body}
        </div>
      )}
    </div>
  )
}

export default Accordion
