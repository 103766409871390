import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {KTSVG} from '../../../_metronic/helpers'

const Horizontal: FC = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const steps = [
    {step: 1, title: 'Edit Details'},
    {step: 2, title: 'Select Authorities'},
    {step: 3, title: 'Edit Authorities'},
    {step: 4, title: 'Finish'},
  ]

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }
  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const calculateWidth = (): string => {
    let width: string = '0%'
    switch (currentStep) {
      case 1:
        width = '25%'
        break
      case 2:
        width = '50%'
        break
      case 3:
        width = '75%'
        break
      case 4:
        width = '100%'
        break
      default:
        break
    }
    return width
  }
  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>CNLOPB RQ Edit Wizard</div>
      </div>
      <div className='card-body'>
        <div className='stepper stepper-links d-flex flex-column pt-15'>
          <div className='stepper-nav mb-5'>
            {steps.map((step) => (
              <div
                className={`stepper-item ${step.step === currentStep && 'current'}`}
                data-kt-stepper-element='nav'
              >
                <h3 className='stepper-title'>{step.title}</h3>
              </div>
            ))}
          </div>

          <div className='mx-auto mw-1000px w-100 pt-5'>
            <div className='row mb-10'>
              <div className='col-md-12'>
                <div className='d-flex align-items-center flex-column'>
                  <div className='h-10px mx-3 w-100 bg-light my-5'>
                    <div
                      className='bg-success rounded h-10px'
                      role='progressbar'
                      style={{width: calculateWidth()}}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            {steps.map(
              (step, key) =>
                step.step === currentStep && (
                  <div className='current'>
                    {step.step === 1 && <Step1 />}
                    {step.step === 2 && <Step2 />}
                    {step.step === 3 && <Step3 />}
                    {step.step === 4 && <Step4 />}
                  </div>
                )
            )}

            <div className='d-flex flex-stack pt-15'>
              {currentStep > 1 ? (
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    Back
                  </button>
                </div>
              ) : (
                <div></div>
              )}
              {currentStep < 4 ? (
                <div>
                  <button onClick={nextStep} className='btn btn-lg btn-primary'>
                    <span className='indicator-label'>
                      Continue
                      {/* {isSubmitButton && 'Submit'} */}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Horizontal}
