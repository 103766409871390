import React, {FC} from 'react'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <div className='row'>
          <div className='col-md-6 pe-5'>
            <div className='mb-4'>
              <h4 className='text-primary mb-3'>Operator's Concurrence (If Not Applicant)</h4>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.OperatorName'>
                  Name<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_OperatorName'
                    name='RQV.OperatorName'
                    placeholder='Enter Operator Name'
                    required
                    type='text'
                    value='BP Demo Guy'
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.OperatorTitle'>
                  Title
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_OperatorTitle'
                    name='RQV.OperatorTitle'
                    placeholder='Enter Operator Title'
                    type='text'
                    value=''
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.OperatorEmail'>
                  Email<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_OperatorEmail'
                    name='RQV.OperatorEmail'
                    placeholder='Enter Operator Email'
                    required
                    type='email'
                    value='bpguy@wesi.ca'
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.OperatorPhoneNumber'>
                  Phone Number
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_OperatorPhoneNumber'
                    name='RQV.OperatorPhoneNumber'
                    placeholder='Enter Operator Phone Number'
                    type='number'
                    value='9024043052'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 ps-5'>
            <div className='mb-4'>
              <h4 className='text-primary'>
                Workplace Committee or Coordinator Consulted (If Applicable)
              </h4>
              <p className='text-danger font-12 mt-0'>
                (Accord Act, Part III.1 (Federal Version), paragraph 210.07(5)(b))
              </p>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.CommitteeName'>
                  Name<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_CommitteeName'
                    name='RQV.CommitteeName'
                    placeholder='Enter Committee Name'
                    required
                    type='text'
                    value='Phil Nash'
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.CommitteeTitle'>
                  Title
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_CommitteeTitle'
                    name='RQV.CommitteeTitle'
                    placeholder='Enter Committee Title'
                    type='text'
                    value=''
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.CommitteeEmail'>
                  Email<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_CommitteeEmail'
                    name='RQV.CommitteeEmail'
                    placeholder='Enter Committee Email'
                    required
                    type='email'
                    value='p.nash@wesi.ca'
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.CommitteePhoneNumber'>
                  Phone Number
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_CommitteePhoneNumber'
                    name='RQV.CommitteePhoneNumber'
                    placeholder='Enter Committee Phone Number'
                    type='number'
                    value='9024043052'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6 pe-5 cnsopb-decision cnlopb-decision'>
            <div className='mb-4'>
              <h4 className='text-primary mb-3'>Board Decision</h4>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.BoardName'>
                  Name<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_BoardName'
                    name='RQV.BoardName'
                    placeholder='Enter Board Name'
                    type='text'
                    value=''
                    required
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.BoardTitle'>
                  Title
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_BoardTitle'
                    name='RQV.BoardTitle'
                    placeholder='Enter Board Title'
                    type='text'
                    value=''
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.BoardEmail'>
                  Email<span className='text-danger ml-1'>*</span>
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_BoardEmail'
                    name='RQV.BoardEmail'
                    placeholder='Enter Board Email'
                    type='email'
                    value=''
                    required
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.BoardPhoneNumber'>
                  Phone Number
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_BoardPhoneNumber'
                    name='RQV.BoardPhoneNumber'
                    placeholder='Enter Board Phone Number'
                    type='number'
                    value=''
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.Decision'>
                  Decision
                </label>
                <div className='col-md-12'>
                  <textarea
                    className='form-control'
                    cols={20}
                    id='RQV_Decision'
                    name='RQV.Decision'
                    placeholder='Add Decision'
                    rows={2}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 ps-5 transport-canada-approval' style={{display: 'none'}}>
            <div className='mb-4' id='transportapproval'>
              <h4 className='text-primary mb-3'>Transport Canada Approval</h4>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.TransportCanadaRep'>
                  Transport Canada Representative
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_TransportCanadaRep'
                    name='RQV.TransportCanadaRep'
                    placeholder='Enter Transport Canada Representative Name'
                    type='text'
                    value=''
                  />
                </div>
              </div>
              <div className='form-group row mb-3'>
                <label className='col-md-12 col-form-label' htmlFor='RQV.TransportCanadaRepEmail'>
                  Email
                </label>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    id='RQV_TransportCanadaRepEmail'
                    name='RQV.TransportCanadaRepEmail'
                    placeholder='Enter TransportCanadaRep Email'
                    type='email'
                    value=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
