/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'

type Props = {}

const InviteUsers: FC<Props> = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Invite Users
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>Invite Users</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12 mb-10'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-header bg-primary d-flex align-items-center'>
            <h3 className='m-0 text-white'>Users with Access</h3>
          </div>
          <div className='card-body'>
            <Datatable
              search={false}
              columns={[
                {title: 'Name'},
                {title: 'Phone'},
                {title: 'Email'},
                {title: 'Date Joined'},
                {title: ''},
              ]}
              rows={
                <>
                  <tr>
                    <td> Phil Nash</td>
                    <td> 9024043052</td>
                    <td> p.nash@wesi.ca</td>
                    <td> 1/18/2022 5:49:48 PM</td>
                    <td>
                      <Link
                        to='/project/edit-user/5'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                        </span>
                      </Link>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='mh-50px'
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td> Pradeep</td>
                    <td> </td>
                    <td> itspradeepmalviya@gmail.com</td>
                    <td> 2/24/2023 3:46:14 AM</td>

                    <td>
                      <Link
                        to='/project/edit-user/5'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                        </span>
                      </Link>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='mh-50px'
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td> Raghav</td>
                    <td> 9029323380</td>
                    <td> r.gupta@wesi.ca</td>
                    <td> 1/18/2022 6:10:12 PM</td>
                    <td>
                      <Link
                        to='/project/edit-user/5'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                        </span>
                      </Link>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='mh-50px'
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td> Udit Gandhi</td>
                    <td> </td>
                    <td> coder.udit@gmail.com</td>
                    <td> 9/12/2022 12:25:16 PM</td>

                    <td>
                      <Link
                        to='/project/edit-user/5'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                        </span>
                      </Link>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='mh-50px'
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className='col-md-12 mb-10'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-header bg-primary d-flex align-items-center'>
            <h3 className='m-0 text-white'>Users Requesting Access</h3>
          </div>
          <div className='card-body'>
            <Datatable
              search={false}
              columns={[
                {title: 'Name'},
                {title: 'Phone'},
                {title: 'Email'},
                {title: 'Date Joined'},
                {title: ''},
              ]}
              rows={<></>}
            />
          </div>
        </div>
      </div>

      <div className='col-md-12 mb-10'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-header bg-primary d-flex align-items-center'>
            <h3 className='m-0 text-white'>Invite User</h3>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Name</label>
                <input
                  className='form-control'
                  id='inviteUserName'
                  name='name'
                  type='text'
                  value=''
                />
                {/* <div id="errorName" style="display:none; color:red;">Please enter a name</div> */}
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Title</label>
                <input
                  className='form-control'
                  id='inviteUserTitle'
                  name='title'
                  type='text'
                  value=''
                />
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Phone</label>
                <input
                  className='form-control'
                  id='inviteUserPhone'
                  name='phone'
                  type='text'
                  value=''
                />
                {/* <div id="errorPhone" style="display:none; color:red;">Please enter a valid phone number</div> */}
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Email</label>
                <input
                  className='form-control'
                  id='inviteUserEmail'
                  name='email'
                  type='text'
                  value=''
                />
                {/* <div id="errorEmail" style="display:none; color:red;">Please enter a valid email address</div> */}
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Password</label>
                <input
                  className='form-control'
                  id='inviteUserPassword'
                  name='password'
                  type='text'
                  value=''
                />
                {/* <div id="errorPassword" style="display:none; color:red;">
								That is an invalid password. <br>
								Passwords must be at least six characters long. And contain at least one capital letter, lowercase letter, digit, and special character.
							</div> */}
              </div>

              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>User Type</label>
                <select className='form-control' id='userType' name='userType'>
                  <option value='user'>User</option>
                </select>
              </div>

              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Intake Access
                </label>

                <select className='form-control' id='intakeDrop' name='intakeDrop'>
                  <option>None</option>
                  <option>View Only</option>
                  <option>Base User</option>
                  <option>Admin User</option>
                </select>
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Regulatory Access
                </label>

                <select className='form-control' id='regulatoryDrop' name='regulatoryDrop'>
                  <option>None</option>
                  <option>View Only</option>
                  <option>Base User</option>
                  <option>Admin User</option>
                </select>
              </div>
              <div className='col-sm-4 mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Training Access
                </label>

                <select className='form-control' id='trainingDrop' name='trainingDrop'>
                  <option>None</option>
                  <option>View Only</option>
                  <option>Base User</option>
                  <option>Admin User</option>
                </select>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-center py-6 px-9'>
            <button type='submit' className='btn btn-primary mx-2'>
              <i className='bi bi-check2-circle'></i> Save Changes
            </button>
            <button type='submit' className='btn btn-light mx-2'>
              <i className='bi bi-x'></i>Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InviteUsers
