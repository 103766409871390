/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'
import Accordion from '../../components/accordion/Accordion'
import FileDrop from '../../components/fileDrop/FileDrop'

const RegulatoryQueryDetails = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Regulatory Query For Seabed Survey Vessel 1
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Vessel Dashboard
                </a>
              </li>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Regulatory Queries
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>RQ: 11</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='card'>
            <div className='card-body'>
              <div className='d-flex align-items-center mb-5'>
                <div className='d-flex align-items-center flex-grow-1'>
                  <div className='d-flex flex-column'>
                    <div className='fs-3 fw-bolder text-primary d-flex align-items-center'>
                      RQ: 11 <span className='badge badge-info ms-5'>CNLOPB</span>
                    </div>
                  </div>
                </div>
                <div className='m-0'>
                  <a className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-3'>
                    <span className='svg-icon svg-icon-3'>
                      <i className='fa fa-minus'></i>
                    </span>
                  </a>
                  <Link
                    to=' '
                    className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-1'
                  >
                    <span className='svg-icon svg-icon-3'>
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-70px' />
                    </span>
                  </Link>
                </div>
              </div>
              <div className='d-flex'>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Person Responsible
                    </a>
                    <span className='fw-bolder d-block mt-2 d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='mh-70px text-primary me-3'
                      />
                      BP Demo Guy
                    </span>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Priority
                    </a>
                    <div className='badge badge-light-danger d-block mt-2'>High</div>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Subject
                    </a>
                    <span className='fw-bolder d-block mt-2'>
                      Deviation From Installation Regulations Doors 5.1
                    </span>
                  </div>
                </div>
              </div>
              <div className='d-flex pt-5'>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Due Date
                    </a>
                    <span className='fw-bolder d-block mt-2 d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil002.svg'
                        className='mh-70px text-primary me-3'
                      />
                      03 Feb 2022
                    </span>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Status
                    </a>

                    <span className='fw-bolder d-block mt-2 d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen013.svg'
                        className='mh-70px text-primary me-3'
                      />
                      In Progress
                    </span>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      File Number
                    </a>
                    <span className='fw-bolder d-block mt-2 d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil012.svg'
                        className='mh-70px text-primary me-3'
                      />
                      11
                    </span>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      Section
                    </a>
                    <div className='badge badge-light-info d-block mt-2'>NA</div>
                  </div>
                </div>
                <div className='rounded border border-secondary border-dashed p-5 me-5'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 fs-6'>
                      SOR
                    </a>
                    <div className='badge badge-light-info d-block mt-2'>NA</div>
                  </div>
                </div>
              </div>

              <div className='separator my-4'></div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Regulation
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>
                      Every double-action swinging door that is located in an exit, entrance or
                      passageway used for two-way pedestrian traffic must be designed and fitted in
                      a manner that will permit persons who are approaching from one side of the
                      door to be aware of persons who are on the other side of the door.
                    </i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Proposed Deviation
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Rationale
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Hazard and associated risk mitigated by the regulation
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Measures to mitigate the risk
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Discussion on how the proposed deviation reduces risk to ALARP
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Precedence, if any
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
              <div className='py-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 fs-6'>
                    Decision of the Board
                  </a>
                  <span className='d-block mt-2 d-flex align-items-center'>
                    <i>NA</i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='card mt-5'>
                <div className='card-header'>
                  <div className='card-title w-100 d-flex justify-content-between'>
                    <h5> Older RQ Versions </h5>
                    <a className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-1'>
                      <span className='svg-icon svg-icon-3'>
                        <i className='fa fa-minus'></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='card-body'>
                  <p className='text-dark'>
                    <i>No older versions exist!</i>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='card mt-5'>
                <div className='card-header'>
                  <div className='card-title w-100 d-flex justify-content-between'>
                    <h5>Attach Files</h5>
                    <a className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-1'>
                      <span className='svg-icon svg-icon-3'>
                        <i className='fa fa-minus'></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className='card-body'>
                  <p className='text-danger'>
                    <i>Please note only (.pdf) type files can be attached to an RQ Export</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card mb-5'>
            <div className='card-header'>
              <h5 className='card-title'>Action Management</h5>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center'>
                <div className='btn btn-sm btn-primary'>View Action Items</div>
                <div className='btn btn-sm btn-primary ms-5'>Bulk Import Action Items</div>
              </div>
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title w-100 d-flex justify-content-between'>
                <h5> Generate RQ with Attachments </h5>
                <a className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-1'>
                  <span className='svg-icon svg-icon-3'>
                    <i className='fa fa-minus'></i>
                  </span>
                </a>
              </div>
            </div>
            <div className='card-body'>
              <p className='text-danger'>
                <i>
                  Please select the files in the order you want them to be attached in the Exported
                  RQ
                </i>
              </p>
              <Accordion
                title={'Available Files(0)'}
                body={
                  <div className='d-flex justify-content-center mt-5'>
                    <div className='alert alert-warning'>No Files Found!</div>
                  </div>
                }
                textColor={'primary'}
                isActive={false}
              />

              <Accordion
                title={'Files Selected for Export'}
                body={
                  <>
                    <button
                      type='submit'
                      className='btn btn-success ladda-button btn-sm mb-2 ml-2 mr-2 generate_rq_wesi d-block'
                    >
                      Generate PDF for Signing Process
                    </button>
                    <a target='_self' className='btn btn-success btn-sm mb-2 ml-2 mr-2'>
                      Download Current Version{' '}
                    </a>
                  </>
                }
                textColor={'warning'}
                isActive={true}
              />
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title w-100 d-flex justify-content-between'>
                <h5> Authorities Signature Status </h5>
                <a className='btn btn-icon btn-bg-light bg-hover-light-primary btn-active-color-primary btn-sm me-1'>
                  <span className='svg-icon svg-icon-3'>
                    <i className='fa fa-minus'></i>
                  </span>
                </a>
              </div>
            </div>
            <div className='card-body'>
              <p className='text-warning'>
                <i>
                  If you see all the buttons disabled please click on{' '}
                  <span className='text-danger fw-bold'>Generate PDF for Signing Process</span>{' '}
                  button to intiate signing process for the respective version
                </i>
              </p>

              <Datatable
                search={false}
                columns={[{title: 'Authority'}, {title: 'Profile'}, {title: 'Signature Status'}]}
                rows={
                  <>
                    <tr>
                      <td> Workplace Committee </td>
                      <td>
                        <div>
                          <p className='fw-bold m-0'>Phil Nash</p>
                          <p className='m-0 text-gray-500 fs-8'>
                            <i>9024043052</i>
                          </p>
                          <p className='m-0 text-gray-500 fs-8'>
                            <i>p.nash@wesi.ca</i>
                          </p>
                        </div>
                      </td>
                      <td>
                        <span className='btn btn-sm btn-primary'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com002.svg'
                            className='mh-50px me-3'
                          />
                          Send Email
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td> Operator </td>
                      <td>
                        <div>
                          <p className='fw-bold m-0'>BP Demo Guy</p>
                          <p className='m-0 text-gray-500 fs-8'>
                            <i>9024043052</i>
                          </p>
                          <p className='m-0 text-gray-500 fs-8'>
                            <i>bpguy@wesi.ca</i>
                          </p>
                        </div>
                      </td>
                      <td>
                        <span className='btn btn-sm btn-primary'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com002.svg'
                            className='mh-50px me-3'
                          />
                          Send Email
                        </span>
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegulatoryQueryDetails
