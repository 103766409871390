import React from 'react'
import FileDrop from '../../components/fileDrop/FileDrop'

const AddVessel = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Add Vessel
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Dev team Testing
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>Add Vessel</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-xl-6'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_Name'
                      >
                        Name
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control'
                        id='Vessel_Name'
                        name='Vessel.Name'
                        required
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_IMONumber'
                      >
                        IMO Number
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control'
                        id='Vessel_IMONumber'
                        name='Vessel.IMONumber'
                        required
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <label
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                    htmlFor='Vessel_Description'
                  >
                    Description
                    <span className='text-danger'>*</span>
                  </label>

                  <textarea
                    className='form-control'
                    cols={20}
                    id='Vessel_Description'
                    name='Vessel.Description'
                    required
                    rows={2}
                  ></textarea>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_ArrivalDate'
                      >
                        ArrivalDate
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control form-control input'
                        placeholder=''
                        required
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_WaterDepth'
                      >
                        Water Depth (Feet)
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control'
                        id='Vessel_WaterDepth'
                        name='Vessel.WaterDepth'
                        required
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_YearBuilt'
                      >
                        Year Built
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control'
                        id='Vessel_YearBuilt'
                        name='Vessel.YearBuilt'
                        required
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_Status'
                      >
                        Status
                      </label>
                      <input
                        value=''
                        className='form-control'
                        id='Vessel_Status'
                        name='Vessel.Status'
                        required
                        type='text'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_Operator'
                      >
                        Operator
                      </label>
                      <input
                        value=''
                        className='form-control'
                        id='Vessel_Operator'
                        name='Vessel.Operator'
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_DayRate'
                      >
                        Contract DayRate (USD)
                      </label>
                      <input
                        value=''
                        className='form-control'
                        id='Vessel_DayRate'
                        name='Vessel.DayRate'
                        type='text'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-5 mt-2 mt-xl-0'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='projectname'
                      >
                        Upload Vessel Picture
                      </label>
                      <div className='dropify-wrapper'>
                        <FileDrop />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_Owner'
                      >
                        Owner
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control'
                        id='Vessel_Owner'
                        name='Vessel.Owner'
                        required
                        type='text'
                        value=''
                      />
                    </div>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_FlagState'
                      >
                        Flag State
                        <span className='text-danger'>*</span>
                      </label>

                      <select
                        className='form-control select_file_type btn-dp-blck'
                        id='Vessel_FlagState'
                        name='Vessel.FlagState'
                      >
                        <option value=''>-- Select One --</option>

                        <option>India</option>
                      </select>
                    </div>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_VesselTypeId'
                      >
                        Vessel Type
                        <span className='text-danger'>*</span>
                      </label>

                      <select
                        className='form-control select_file_type btn-dp-blck'
                        id='Vessel_VesselTypeId'
                        name='Vessel.VesselTypeId'
                      >
                        <option value=''>-- Select One --</option>
                        <option value=''>Mikes Vessel Type</option>
                        <option value=''>Test</option>
                        <option value=''>Lorri's Test</option>
                        <option value=''>Supply Vessel</option>
                        <option value=''>Standby Vessel</option>
                        <option value=''>Accommodations Support Vessel</option>
                        <option value=''>Drill Ship</option>
                        <option value=''>Semi-Submersible</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_EstimatedStartDate'
                      >
                        Estimated Start Date
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control  form-control input'
                        placeholder=''
                        required
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-5'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mb-2'
                        htmlFor='Vessel_EstimatedEndDate'
                      >
                        Estimated End Date
                        <span className='text-danger'>*</span>
                      </label>

                      <input
                        className='form-control  form-control input'
                        placeholder=''
                        required
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-5 row mb-3 justify-content-end'>
                  <div className='col-12 mt-2'>
                    <label className='form-check form-check-sm form-check-solid'>
                      <input
                        className='form-check-input mt-1'
                        type='checkbox'
                        value='2'
                        checked={true}
                      />
                      <span className='form-check-label'>
                        <label
                          className='d-flex align-items-center fs-5 mb-2'
                          htmlFor='Boilerplate'
                        >
                          If you would like to have this vessel come with preloaded positions, their
                          competencies and required training, click the check box to the right.
                          These can be altered within the training module at a later time. This
                          process may take several minutes.
                        </label>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-center py-6 px-9'>
            <button type='submit' className='btn btn-primary mx-2'>
              <i className='bi bi-check2-circle'></i> Save Changes
            </button>
            <button type='submit' className='btn btn-light mx-2'>
              <i className='bi bi-x'></i>Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddVessel
