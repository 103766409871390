import {put, takeLatest, fork, call} from 'redux-saga/effects'

import {createApiCall, loginRoute, verifyTokenRoute, MethodType} from '../../services/Api'
import {ActionType} from '../../model/model'

// login
function* loginSaga({payload}: any): Generator<any> {
  const {data, callback} = payload
  try {
    const {api_token}: any = yield call(createApiCall, {
      method: MethodType.POST,
      url: loginRoute,
      data: data,
    })

    const response: any = yield call(createApiCall, {
      method: MethodType.POST,
      url: verifyTokenRoute,
      data: {
        api_token: api_token,
      },
    })

    if (response) {
      callback(response)
      yield put({type: ActionType.LOGIN_USER_SUCCESS, payload: response})
    } else {
      yield put({type: ActionType.LOGIN_USER_ERROR, payload: 'error'})
    }
  } catch (error) {
    yield put({type: ActionType.LOGIN_USER_ERROR, payload: 'error'})
  }
}

function* onLoginSubmitWatcher() {
  yield takeLatest(ActionType.LOGIN_USER as any, loginSaga)
}

export default [fork(onLoginSubmitWatcher)]
