/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

type Props = {}

const ProjectList: FC<Props> = () => {
  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 mb-xl-10'>
        <div className='card mb-5 h-100'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='d-flex flex-column'>
                  <Link
                    to='/project/1'
                    className='fs-3 fw-bolder text-dark text-hover-primary cursor-pointer'
                  >
                    Dev team Testing
                  </Link>
                </div>
              </div>
              <div className='my-0'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  <span className='svg-icon svg-icon-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen007.svg'
                      className='svg-icon-muted svg-icon'
                    />
                  </span>
                </button>
              </div>
            </div>
            <div className='d-flex'>
              <div className='bg-light-primary rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Project Manager
                  </a>
                  <span className='text-primary fw-semibold d-block'>Raghav G</span>
                </div>
              </div>
              <div className='bg-light-success rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Start Date
                  </a>
                  <span className='text-success fw-semibold d-block'>26 Aug 2020</span>
                </div>
              </div>
              <div className='bg-light-danger rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Target Closure Date
                  </a>
                  <span className='text-danger fw-semibold d-block'>31 Dec 2020</span>
                </div>
              </div>
            </div>
            <div className='mt-5'>
              <div className='d-flex'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                    <div className='fs-6 text-gray-800 fw-bolder'>Members</div>

                    <div className='d-flex mt-2'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        aria-describedby='tooltip-user-name'
                      >
                        <img src='media/avatars/300-14.jpg' alt='Pic' />
                        <img src='media/avatars/300-15.jpg' alt='Pic' />
                        <img src='media/avatars/300-13.jpg' alt='Pic' />
                        <img src='media/avatars/300-16.jpg' alt='Pic' />
                        <img src='media/avatars/300-17.jpg' alt='Pic' />
                        <img src='media/avatars/300-18.jpg' alt='Pic' />
                        <img src='media/avatars/300-19.jpg' alt='Pic' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                    <div className='fs-6 text-gray-800 fw-bolder'>Modules</div>
                    <div className='d-flex mt-2'>
                      <a
                        href='#'
                        className='btn btn-sm bg-light-primary text-primary px-4 py-2 me-4'
                      >
                        Regulatory
                      </a>
                      <a href='#' className='btn btn-sm bg-light-warning text-warning px-4 py-2'>
                        Intake
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='fs-6 text-gray-800 fw-bolder'>Description</div>
              <span className='text-muted fw-semibold fs-7'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum voluptatem
                accusamus quisquam earum corrupti quae sed esse perferendis autem amet?
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-6 mb-xl-10'>
        <div className='card mb-5 h-100'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-5'>
              <div className='d-flex align-items-center flex-grow-1'>
                <div className='d-flex flex-column'>
                  <Link
                    to='/project/1'
                    className='fs-3 fw-bolder text-dark text-hover-primary cursor-pointer'
                  >
                    BP Deepwater Exploration 2023
                  </Link>
                </div>
              </div>
              <div className='my-0'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                >
                  <span className='svg-icon svg-icon-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen007.svg'
                      className='svg-icon-muted svg-icon'
                    />
                  </span>
                </button>
              </div>
            </div>
            <div className='d-flex'>
              <div className='bg-light-primary rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Project Manager
                  </a>
                  <span className='text-primary fw-semibold d-block'>Phil Nash</span>
                </div>
              </div>
              <div className='bg-light-success rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Start Date
                  </a>
                  <span className='text-success fw-semibold d-block'>18 Jan 2022</span>
                </div>
              </div>
              <div className='bg-light-danger rounded p-5 me-5'>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-800 fs-6'>
                    Target Closure Date
                  </a>
                  <span className='text-danger fw-semibold d-block'>01 Nov 2023</span>
                </div>
              </div>
            </div>
            <div className='mt-5'>
              <div className='d-flex'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                    <div className='fs-6 text-gray-800 fw-bolder'>Members</div>

                    <div className='d-flex mt-2'>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        aria-describedby='tooltip-user-name'
                      >
                        <img src='media/avatars/300-6.jpg' alt='Pic' />
                        <img src='media/avatars/300-7.jpg' alt='Pic' />
                        <img src='media/avatars/300-8.jpg' alt='Pic' />
                        <img src='media/avatars/300-9.jpg' alt='Pic' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-5 mb-3'>
                    <div className='fs-6 text-gray-800 fw-bolder'>Modules</div>

                    <div className='d-flex mt-2'>
                      <a
                        href='#'
                        className='btn btn-sm bg-light-primary text-primary px-4 py-2 me-4'
                      >
                        Regulatory
                      </a>
                      <a
                        href='#'
                        className='btn btn-sm bg-light-warning text-warning px-4 py-2 me-4'
                      >
                        Intake
                      </a>
                      <a href='#' className='btn btn-sm bg-light-success text-success px-4 py-2'>
                        Training
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='fs-6 text-gray-800 fw-bolder'>Description</div>
              <span className='text-muted fw-semibold fs-7'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur, impedit!
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectList
