/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'

type Props = {}

const VesselIntakeDetails: FC<Props> = () => {
  const [isShowChecklist, setIsShowChecklist] = useState('')
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Vessel Intake Details
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fw-bold fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>Vessel Intake Details</li>
            </ol>
          </div>
        </div>
      </div>

      <div className='col-md-12 mb-5'>
        <div className='card'>
          <div className='card-header d-flex align-items-center'>
            <h4 className='m-0'>Vessel Information Details</h4>
          </div>
          <div className='card-body'>
            <div className='d-flex flex-wrap'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-600'>IMO Number</div>
                </div>
                <div className='fs-5 fw-bold mt-2'>5465</div>
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-600'>Vessel Owner</div>
                </div>
                <div className='fs-5 fw-bold mt-2'>Maersk</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-600'>Flag State</div>
                </div>
                <div className='fs-5 fw-bold mt-2'>
                  <span className='symbol symbol-20px me-2'>
                    <img
                      className='rounded-1'
                      src='https://preview.keenthemes.com/metronic8/react/demo1/media/flags/united-states.svg'
                      alt='metronic'
                    />
                  </span>{' '}
                  Barbados
                </div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-600'>Vessel Type</div>
                </div>
                <div className='fs-5 fw-bold mt-2'>Semi-Submersible</div>
              </div>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-6 text-gray-600'>Intake Quick Links</div>
                </div>
                <div className='d-flex'>
                  <Link
                    to='/vessel-intake/regulatory-query/5'
                    className='btn btn-sm px-3 py-1 btn-primary me-4 mt-2 cursor-pointer'
                  >
                    RQ Dashboard
                  </Link>
                  <Link
                    to='/vessel-intake/action-items/5'
                    className='btn btn-sm px-3 py-1 btn-primary me-4 mt-2 cursor-pointer'
                  >
                    View all Vessel Actions
                  </Link>
                  <div className='btn btn-sm px-3 py-1 btn-primary mt-2 cursor-pointer'>
                    Export Intake Checklist Summary
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-5'>
        <div className='col-md-5'>
          <div className='card mb-5 mb-xxl-8 h-100'>
            <div className='card-header d-flex align-items-center'>
              <h5 className='m-0'>Users Responsible</h5>
            </div>
            <div className='card-body'>
              <Datatable
                search={false}
                columns={[{title: 'Profile'}, {title: 'Email'}]}
                rows={
                  <>
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <div className='symbol symbol-40px me-5'>
                            <img alt='Logo' src=' /media/avatars/300-1.jpg' />
                          </div>
                          <div className='div d-flex flex-column'>
                            <div>Blair MacDougall</div>
                            <div className='text-gray-500'>Member Since 1/18/2022</div>
                          </div>
                        </div>
                      </td>
                      <td> Blair.Macdougall@WESI.ca </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <div className='symbol symbol-40px me-5'>
                            <img alt='Logo' src=' /media/avatars/300-9.jpg' />
                          </div>
                          <div className='div d-flex flex-column'>
                            <div>Udit Gandhi</div>
                            <div className='text-gray-500'>Member Since 9/12/2022</div>
                          </div>
                        </div>
                      </td>
                      <td> coder.udit@gmail.com</td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <div className='symbol symbol-40px me-5'>
                            <img alt='Logo' src=' /media/avatars/300-1.jpg' />
                          </div>
                          <div className='div d-flex flex-column'>
                            <div>Blair MacDougall</div>
                            <div className='text-gray-500'>Member Since 1/18/2022</div>
                          </div>
                        </div>
                      </td>
                      <td> Blair.Macdougall@WESI.ca </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='d-flex'>
                          <div className='symbol symbol-40px me-5'>
                            <img alt='Logo' src=' /media/avatars/300-9.jpg' />
                          </div>
                          <div className='div d-flex flex-column'>
                            <div>Udit Gandhi</div>
                            <div className='text-gray-500'>Member Since 9/12/2022</div>
                          </div>
                        </div>
                      </td>
                      <td> coder.udit@gmail.com</td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>

        <div className='col-md-7'>
          <div className='card mb-5 mb-xxl-8 h-100'>
            <div className='card-header d-flex align-items-center'>
              <h5 className='m-0'>Regulatory Compliance Verification Summary</h5>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-5'>
        <h3 className='py-5'>Intake Checklists</h3>
        <div className='col-md-4'>
          <div className='card mb-xxl-8'>
            <div className='card-header d-flex align-items-center'>
              <h5 className='m-0'>Regulations</h5>
            </div>
            <div className='card-body'>
              <div className='d-flex'>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex justify-content-between mb-1'>
                    <span className='text-muted fs-7 fw-semibold'>CHECKLIST PROGRESS</span>
                    <span className='text-muted fs-7 fw-semibold'>50%</span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-success'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between my-10'>
                <div
                  className='badge badge-secondary cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Applicable'
                >
                  03 NA
                </div>
                <div
                  className='badge badge-danger cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Inspected'
                >
                  1306 NI
                </div>
                <div
                  className='badge badge-info cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Non Compliant'
                >
                  04 NC
                </div>
                <div
                  className='badge badge-warning cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Verification Required'
                >
                  18 VR
                </div>
                <div
                  className='badge badge-success cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Fully Compliant'
                >
                  02 FC
                </div>
              </div>
              <div
                className='btn btn-light-primary d-block border border-primary'
                onClick={() =>
                  setIsShowChecklist(isShowChecklist === 'Regulations' ? '' : 'Regulations')
                }
              >
                Show Checklists
              </div>
              {isShowChecklist === 'Regulations' && (
                <div className='mt-5'>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>Drilling and Production</a>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>
                    Frontier and Offshore Regulatory Renewal Initiative
                  </a>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>
                    Occupational Health and Safety
                  </a>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>
                    Offshore Installation Regulations
                  </a>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>Test2 BoilerPlate</a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='card mb-5 mb-xxl-8'>
            <div className='card-header d-flex align-items-center'>
              <h5 className='m-0'>Codes, Standards and Industry Practices</h5>
            </div>
            <div className='card-body'>
              <div className='d-flex'>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex justify-content-between mb-1'>
                    <span className='text-muted fs-7 fw-semibold'>CHECKLIST PROGRESS</span>
                    <span className='text-muted fs-7 fw-semibold'>20%</span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-success'
                      role='progressbar'
                      style={{width: '20%'}}
                    ></div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between my-10'>
                <div
                  className='badge badge-secondary cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Applicable'
                >
                  03 NA
                </div>
                <div
                  className='badge badge-danger cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Inspected'
                >
                  1306 NI
                </div>
                <div
                  className='badge badge-info cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Non Compliant'
                >
                  04 NC
                </div>
                <div
                  className='badge badge-warning cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Verification Required'
                >
                  18 VR
                </div>
                <div
                  className='badge badge-success cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Fully Compliant'
                >
                  02 FC
                </div>
              </div>
              <div
                className='btn btn-light-primary d-block border border-primary'
                onClick={() => setIsShowChecklist(isShowChecklist === 'Codes' ? '' : 'Codes')}
              >
                Show Checklists
              </div>
              {isShowChecklist === 'Codes' && (
                <div className='mt-5'>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>Example of Type Code</a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='card mb-5 mb-xxl-8'>
            <div className='card-header d-flex align-items-center'>
              <h5 className='m-0'>Safety Notices and Interpretation Notes</h5>
            </div>
            <div className='card-body'>
              <div className='d-flex'>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex justify-content-between mb-1'>
                    <span className='text-muted fs-7 fw-semibold'>CHECKLIST PROGRESS</span>
                    <span className='text-muted fs-7 fw-semibold'>73%</span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-success'
                      role='progressbar'
                      style={{width: '73%'}}
                    ></div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between my-10'>
                <div
                  className='badge badge-secondary cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Applicable'
                >
                  03 NA
                </div>
                <div
                  className='badge badge-danger cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Not Inspected'
                >
                  1306 NI
                </div>
                <div
                  className='badge badge-info cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Non Compliant'
                >
                  04 NC
                </div>
                <div
                  className='badge badge-warning cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Verification Required'
                >
                  18 VR
                </div>
                <div
                  className='badge badge-success cursor-pointer'
                  data-toggle='tooltip'
                  data-theme='dark'
                  title='Fully Compliant'
                >
                  02 FC
                </div>
              </div>
              <div
                className='btn btn-light-primary d-block border border-primary'
                onClick={() => setIsShowChecklist(isShowChecklist === 'Safety' ? '' : 'Safety')}
              >
                Show Checklists
              </div>
              {isShowChecklist === 'Safety' && (
                <div className='mt-5'>
                  <a className='btn btn-sm btn-primary mt-3 d-block'>Safety Notice 2020.01</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VesselIntakeDetails
