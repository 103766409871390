/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Datatable from '../../components/datatable/Datatable'

const ProjectDetail = () => {
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              Edit Project
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'> Edit Project</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-xl-6'>
                  <div className='form-group mb-5'>
                    <label
                      className='d-flex align-items-center fs-5 fw-semibold mb-2'
                      htmlFor='projectname'
                    >
                      Title
                    </label>
                    <input
                      className='form-control'
                      id='projectname'
                      name='Project.Title'
                      type='text'
                    />
                  </div>
                  <div className='form-group mb-5'>
                    <label
                      className='d-flex align-items-center fs-5 fw-semibold mb-2'
                      htmlFor='project-overview'
                    >
                      Description
                    </label>
                    <textarea
                      className='form-control'
                      cols={20}
                      id='project-overview'
                      name='Project.Description'
                      rows={5}
                    ></textarea>
                  </div>
                  <div className='form-group mb-5'>
                    <label
                      className='d-flex align-items-center fs-5 fw-semibold mb-2'
                      htmlFor='Project_ProjectManager_Id'
                    >
                      Project Manager
                    </label>
                    <select
                      className='form-control'
                      id='Project_ProjectManager_Id'
                      name='Project.ProjectManager.Id'
                    >
                      <option value=''> Select </option>
                      <option>Ayman</option>
                    </select>
                  </div>
                </div>
                <div className='col-xl-6'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group mb-5'>
                        <label
                          className='d-flex align-items-center fs-5 fw-semibold mb-2'
                          htmlFor='Project_StartDate'
                        >
                          Start Date
                        </label>

                        <input
                          className='form-control form-control input'
                          placeholder=''
                          tabIndex={0}
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group mb-5'>
                        <label
                          className='d-flex align-items-center fs-5 fw-semibold mb-2'
                          htmlFor='Project_SpudDate'
                        >
                          Spud Date
                        </label>

                        <input
                          className='form-control form-control input'
                          tabIndex={0}
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group mb-5'>
                        <label
                          className='d-flex align-items-center fs-5 fw-semibold mb-2'
                          htmlFor='Project_TargetClosureDate'
                        >
                          Target Closure Date
                        </label> 
                        <input
                          className='form-control form-control input'
                          placeholder=''
                          tabIndex={0}
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group mb-5'>
                        <label
                          className='d-flex align-items-center fs-5 fw-semibold mb-2'
                          htmlFor='Project_ActualEndDate'
                        >
                          Actual Closure Date
                        </label>

                        <input
                          className='form-control form-control input'
                          placeholder=''
                          tabIndex={0}
                          type='text'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group project-create'>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                          Modules
                        </label>
                      </div>
                    </div>

                    <div className='col-lg-3'>
                      <div className='form-group mb-5'>
                        <label className='form-check form-check-sm form-check-solid'>
                          <label
                            className='d-flex align-items-center cursor-pointer'
                            htmlFor='HasIntake'
                          >
                            <input
                              id='HasIntake'
                              className='form-check-input me-2'
                              type='checkbox'
                              value='2'
                            />
                            HasIntake
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className='col-lg-3'>
                      <div className='form-group mb-5'>
                        <label className='form-check form-check-sm form-check-solid'>
                          <label
                            className='d-flex align-items-center cursor-pointer'
                            htmlFor='HasRegulatory'
                          >
                            <input
                              id='HasRegulatory'
                              className='form-check-input me-2'
                              type='checkbox'
                              value='2'
                            />
                            HasRegulatory
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className='col-lg-3'>
                      <div className='form-group mb-5'>
                        <label className='form-check form-check-sm form-check-solid'>
                          <label
                            className='d-flex align-items-center cursor-pointer'
                            htmlFor='HasTraining'
                          >
                            <input
                              id='HasTraining'
                              className='form-check-input me-2'
                              type='checkbox'
                              value='2'
                            />
                            HasTraining
                          </label>
                        </label>
                      </div>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group project-create'>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                          Status
                        </label>
                      </div>
                    </div>

                    <div className='col-lg-3'>
                      <div className='form-group mb-5'>
                        <label className='form-check form-check-sm form-check-solid'>
                          <label
                            className='d-flex align-items-center cursor-pointer'
                            htmlFor='IsArchived'
                          >
                            <input
                              id='IsArchived'
                              className='form-check-input me-2'
                              type='checkbox'
                              value='2'
                            />
                            IsArchived
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-center py-6 px-9'>
              <button type='submit' className='btn btn-primary mx-2'>
                <i className='bi bi-check2-circle'></i> Save Changes
              </button>
              <button type='submit' className='btn btn-light mx-2'>
                <i className='bi bi-x'></i>Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetail
