export const apiBaseRoute = process.env.REACT_APP_API_BASE_URL

export enum MethodType {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const API_URL = process.env.REACT_APP_API_URL

export const loginRoute = `/login`
export const verifyTokenRoute = `/verify_token`

export const createApiCall = async ({method = 'GET', url = '', data = {}, auth = false}) => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  return fetch(`${API_URL}${url}`, {
    body: method === 'GET' ? undefined : JSON.stringify(data),
    cache: 'no-cache',
    headers,
    method,
  })
    .then((response) => response.json())
    .then((result) => result)
}
