/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Accordion from '../../components/accordion/Accordion'
import Datatable from '../../components/datatable/Datatable'
import FileDrop from '../../components/fileDrop/FileDrop'
import Modal from '../../components/modal/Modal'

type Props = {}

const VesselActionItems: FC<Props> = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-between'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-between'>
              Vessel Action Items
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fw-bold fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted fw-bold'>Vessel Action Items</li>
            </ol>
          </div>
        </div>
      </div>

      <div className='col-md-12 mt-5'>
        <div className='card'>
          <div className='card-header cursor-pointer align-items-center'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Manage Action Items</h3>
            </div>
            <div className='d-flex'>
              <Link
                to='/project/action-dashboard/2'
                className='btn btn-success d-flex align-items-center me-4'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen008.svg'
                  className='svg-icon-muted svg-icon-1hx'
                />
                <span> View All Action Items</span>
              </Link>
              <Link to='/' className='btn btn-success d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/files/fil022.svg'
                  className='svg-icon-muted svg-icon-1hx'
                />
                <span> Export Action Items</span>
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-6'>
              <div className='col-lg-3 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Person Responsible:
                </label>
                <select className='form-control datatable-input' data-col-index='2'>
                  <option value=''>Select</option>
                  <option value='BP Demo guy'>BP Demo guy</option>
                  <option value='Blair MacDougall'>Blair MacDougall</option>
                  <option value='Phil Nash'>Phil Nash</option>
                </select>
              </div>

              <div className='col-lg-3 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Type:</label>
                <select className='form-control datatable-input' data-col-index='3'>
                  <option value=''>Select</option>
                  <option value='Code'>Code</option>
                  <option value='General'>General</option>
                  <option value='Geophysical Program Authorization (GPA)'>
                    Geophysical Program Authorization (GPA)
                  </option>
                  <option value='OHS'>OHS</option>
                  <option value='RQ'>RQ</option>
                </select>
              </div>
              <div className='col-lg-3 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Priority:</label>
                <select className='form-control datatable-input' data-col-index='5'>
                  <option value=''>Select</option>
                  <option value='High'>High</option>
                  <option value='Low'>Low</option>
                  <option value='Medium'>Medium</option>
                </select>
              </div>
              <div className='col-lg-3 mb-lg-0 mb-6 mt-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  Completed:
                </label>
                <select className='form-control datatable-input' data-col-index='6'>
                  <option value=''>Select</option>
                  <option value='No'>No</option>
                  <option value='Yes'>Yes</option>
                </select>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-sm btn-light btn-active-light-primary me-5'
                data-kt-menu-dismiss='true'
              >
                Reset
              </button>
              <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-12 mt-5'>
        <div className='card mb-5 mb-xxl-8'>
          <div className='card-body'>
            <div className='mt-5'>
              <Datatable
                columns={[
                  {title: 'ID'},
                  {title: 'Name'},
                  {title: 'Person Responsible'},
                  {title: 'Type'},
                  {title: 'Priority'},
                  {title: 'Completed'},
                  {title: 'Issued Date'},
                  {title: 'Target Date'},
                  {title: 'Date Completed'},
                  {title: 'Files'},
                  {title: 'Comments'},
                  {title: 'Actions'},
                ]}
                rows={
                  <>
                    <tr>
                      <td> 1 </td>
                      <td> EA Condition 2.6 </td>
                      <td> BP Demo guy </td>
                      <td>General</td>
                      <td>
                        <div className='badge badge-light-danger'>High </div>
                      </td>
                      <td>
                        <div className='badge badge-light-success'>Yes </div>
                      </td>
                      <td>
                        <div className='badge badge-light-info'>1/19/2022</div>
                      </td>
                      <td>
                        <div className='badge badge-light-danger'>3/31/2022</div>
                      </td>
                      <td>
                        <div className='badge badge-light-info'>1/20/2022</div>
                      </td>
                      <td>
                        <div
                          className='d-flex justify-content-end flex-shrink-0 cursor-pointer'
                          onClick={() => setIsFileModalOpen(true)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil012.svg'
                              className='mh-50px me-2'
                            />
                          </span>
                          Files(0)
                        </div>
                      </td>
                      <td>
                        <div
                          className='d-flex justify-content-end flex-shrink-0 cursor-pointer'
                          onClick={() => setIsCommentModalOpen(true)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com003.svg'
                              className='mh-50px me-2'
                            />
                          </span>
                          Comments(1)
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <div
                            onClick={() => setIsEditModalOpen(true)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </div>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td> 2 </td>
                      <td> EA Condition 3.4</td>
                      <td> Phil Nash</td>
                      <td>General</td>
                      <td>
                        <div className='badge badge-light-danger'>High </div>
                      </td>
                      <td>
                        <div className='badge badge-light-danger'>No </div>
                      </td>
                      <td>
                        <div className='badge badge-light-info'>1/18/2022 </div>
                      </td>
                      <td>
                        <div className='badge badge-light-danger'>3/31/2022 </div>
                      </td>
                      <td></td>
                      <td>
                        <div
                          className='d-flex justify-content-end flex-shrink-0 cursor-pointer'
                          onClick={() => setIsFileModalOpen(true)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil012.svg'
                              className='mh-50px me-2'
                            />
                          </span>
                          Files(1)
                        </div>
                      </td>
                      <td>
                        <div
                          className='d-flex justify-content-end flex-shrink-0 cursor-pointer'
                          onClick={() => setIsCommentModalOpen(true)}
                        >
                          <span className='svg-icon svg-icon-3'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com003.svg'
                              className='mh-50px me-2'
                            />
                          </span>
                          Comments(1)
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <div
                            onClick={() => setIsEditModalOpen(true)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='mh-50px'
                              />
                            </span>
                          </div>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='mh-50px'
                              />
                            </span>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-700px'
        show={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>Edit Action - EA Condition 2.6</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsEditModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          <div className='row'>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Title <span className='text-danger ms-1'>*</span>
              </label>
              <input className='form-control' placeholder='Enter Title' />
            </div>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Person Responsible <span className='text-danger ms-1'>*</span>
              </label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value=''>Blair MacDougall</option>
                <option value=''>BP Demo guy</option>
                <option value=''>Phil Nash</option>
                <option value=''>Pradeep</option>
                <option value=''>Raghav</option>
                <option value=''>Udit Gandhi</option>
                <option value=''>Vinay</option>
              </select>
            </div>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>Vessel</label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value='No'>No</option>
                <option value='Yes'>Yes</option>
              </select>
            </div>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Priority <span className='text-danger ms-1'>*</span>
              </label>
              <select className='form-control'>
                <option value=''>Select</option>
                <option value='No'>No</option>
                <option value='Yes'>Yes</option>
              </select>
            </div>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Date Issued <span className='text-danger ms-1'>*</span>
              </label>
              <input className='form-control' />
            </div>

            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Target Date <span className='text-danger ms-1'>*</span>
              </label>
              <input className='form-control' />
            </div>
            <div className='col-lg-6 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Date Completed
              </label>
              <input className='form-control' />
            </div>

            <div className='col-lg-12 mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Action Taken
              </label>
              <textarea className='form-control' placeholder='Enter Action Taken' />
            </div>
            <div className='col-lg-12'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                Description <span className='text-danger ms-1'>*</span>
              </label>
              <textarea className='form-control' placeholder='Enter Description' />
            </div>
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center py-5'>
          <button type='submit' className='btn btn-primary mx-2'>
            <i className='bi bi-check2-circle'></i> Save
          </button>
          <button
            type='submit'
            className='btn btn-light mx-2'
            onClick={() => setIsEditModalOpen(false)}
          >
            <i className='bi bi-x'></i>Close
          </button>
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={isFileModalOpen}
        handleClose={() => setIsFileModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>EA Condition 2.6 Files</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsFileModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          <Accordion
            title={'Uploaded files'}
            body={
              <div className='d-flex justify-content-center mt-5'>
                <div className='alert alert-warning'>No Files Found!</div>
              </div>
            }
            textColor={'primary'}
            isActive={false}
          />
        </div>
        <div className='bg-light-primary text-primary p-5 fw-bold text-center'>
          Upload a New File
        </div>
        <div className='modal-body px-8'>
          <div className='col-lg-12 mb-6'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              Description <span className='text-danger ms-1'>*</span>
            </label>
            <input className='form-control' />
          </div>
          <div className='col-lg-12 mb-6'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              File <span className='text-danger ms-1'>*</span>
            </label>
            <FileDrop />
          </div>
        </div>
        <div className='modal-footer d-flex align-items-center py-5'>
          <button type='submit' className='btn btn-primary mx-2'>
            <i className='bi bi-check2-circle'></i> Save
          </button>
          <button
            type='submit'
            className='btn btn-light mx-2'
            onClick={() => setIsFileModalOpen(false)}
          >
            <i className='bi bi-x'></i>Close
          </button>
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={isCommentModalOpen}
        handleClose={() => setIsCommentModalOpen(false)}
      >
        <div className='modal-header d-flex align-items-center py-5'>
          <h2 className='mb-0'>EA Condition 2.6 Comments</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setIsCommentModalOpen(false)}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body px-8'>
          {/* <h3 className='header-title mb-2'>Comments (1)</h3> */}
          <div id='cardCollpase7' className='collapse show'>
            <div className='mb-4'>
              <label className='text-dark fw-bold fs-5 my-2' htmlFor='New_Comment'>
                New Comment <span className='text-danger me-2'>*</span>
              </label>
              <textarea
                className='form-control form-control-light mb-2'
                cols={20}
                rows={3}
                id='comment'
                name='comment'
                placeholder='Add Comment'
                required
              ></textarea>{' '}
              <div className='d-flex justify-content-end'>
                <div className='btn-group mb-2 ml-2'>
                  <button type='submit' className='btn btn-primary btn-sm'>
                    Save
                  </button>
                </div>
              </div>
            </div>

            <div className='mt-10'>
              <div className='card border mb-3'>
                <div className='card-body'>
                  <h5 className='mt-0 d-flex justify-content-between'>
                    <a href='contacts-profile.html' className='text-reset'>
                      BP Demo guy
                    </a>
                    <small className='text-muted font-12 text-blue italic float-right'>
                      Added on 1/20/2022
                    </small>
                  </h5>

                  <div className='mt-5 d-flex justify-content-between'>
                    <p> Report was filed with the C-NLOPB on Jan 20, 2022</p>
                    <a className='float-right action-icon'>
                      <Link
                        to='/vessel/edit/:projectId/:vesselId'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <span className='svg-icon svg-icon-3'>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='mh-50px' />
                        </span>
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default VesselActionItems
