/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import Accordion from '../../components/accordion/Accordion'
import Modal from '../../components/modal/Modal'

type Props = {}

const BulkImport: FC<Props> = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  return (
    <div>
      <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
        <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid p-0'>
          <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              General Action Item Bulk Action Import
            </h1>
          </div>
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <ol className='breadcrumb text-muted fs-6 fw-bold'>
              <li className='breadcrumb-item pe-3'>
                <a href='#' className='pe-3'>
                  Projects
                </a>
              </li>
              <li className='breadcrumb-item px-3 text-muted'>
                General Action Item Bulk Action Import
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-9'>
            <div className='card'>
              <div className='card-body p-5'>
                <div className='rounded px-5 py-2 bg-light-danger form-text text-danger mt-0'>
                  <span className='fw-bold'>
                    <i> Only application template(.xlsx) accepted.</i>
                  </span>{' '}
                  <i> Please download, if unavailiable</i>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                  <div className='upload' id='kt_upload_5'>
                    <input
                      className='upload-FileInput-input upload-input-control d-none'
                      type='file'
                      name='files[]'
                      multiple={true}
                      id='kt_upload_5_input_control'
                    />
                    <label
                      className='upload-input-label btn btn-light-primary btn-sm'
                      htmlFor='kt_upload_5_input_control'
                    >
                      <KTSVG
                        path='/media/icons/duotune/files/fil003.svg'
                        className='svg-icon-muted svg-icon-1hx'
                      />{' '}
                      Attach files
                    </label>
                  </div>

                  <div className='btn btn-sm btn-primary d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil021.svg'
                      className='svg-icon-muted svg-icon-1hx'
                    />
                    <span> Import Excel File</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='card'>
              <div className='card-body p-5'>
                <div className='d-flex flex-column'>
                  <div className='btn btn-sm btn-light-info d-flex align-items-center mb-3'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil017.svg'
                      className='svg-icon-muted svg-icon-1hx'
                    />
                    <span> Download Template</span>
                  </div>
                  <div className='btn btn-sm btn-light-primary d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil016.svg'
                      className='svg-icon-muted svg-icon-1hx'
                    />
                    <span> Master Action Register</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-12 mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header cursor-pointer align-items-center'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Add Action Item Manually</h3>
                </div>
                <div className='btn btn-primary d-flex align-items-center'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr013.svg'
                    className='svg-icon-muted svg-icon-1hx'
                  />
                  <span>Add to Master Grid</span>
                </div>
              </div>
              <div className='card-body pt-0'>
                <div className='row'>
                  <div className='col-xl-4 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='ti'
                      >
                        Title
                        <span className='text-danger ms-1'>*</span>
                      </label>

                      <input
                        className='form-control ti'
                        id='ti'
                        name='ActionItem.Title'
                        type='text'
                        value=''
                      />
                    </div>
                  </div>
                  <div className='col-xl-4 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='des'
                      >
                        Description
                        <span className='text-danger ms-1'>*</span>
                      </label>

                      <textarea
                        className='form-control ds'
                        cols={20}
                        id='des'
                        name='ActionItem.Description'
                        required
                        rows={3}
                      ></textarea>
                    </div>
                  </div>
                  <div className='col-xl-4 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='act'
                      >
                        Action Taken
                      </label>
                      <textarea
                        className='form-control act'
                        cols={20}
                        id='act'
                        name='ActionItem.ActionTaken'
                        rows={3}
                      ></textarea>
                    </div>
                  </div>

                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='per'
                      >
                        Person Responsible <span className='text-danger ms-1'>*</span>
                      </label>

                      <select
                        className='form-control pr'
                        data-toggle='select2'
                        id='per'
                        name='ActionItem.PersonResponsibleId'
                        required
                      >
                        <option value=''>-- Select Person--</option>
                        <option value='1732c937-964d-4a56-b0ca-16881461590f'>
                          Blair MacDougall
                        </option>
                        <option value='4af0dfbe-0e71-4212-bd8c-6468988be954'>Pradeep</option>
                        <option value='e77f12da-3932-42c1-a824-b9425b841c36'>Raghav</option>
                        <option value='3d2ec324-8a37-47d0-ac8c-3ce765ff9532'>Udit Gandhi</option>
                        <option value='49d47c92-7bf9-440f-a385-406349fa8bf6'>Vinay</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='pri'
                      >
                        Priority <span className='text-danger ms-1'>*</span>
                      </label>

                      <select
                        className='form-control pri'
                        data-toggle='select2'
                        id='pri'
                        name='ActionItem.Priority'
                        required
                      >
                        <option value=''>-- Select One --</option>
                        <option>High</option>
                        <option>Medium</option>
                        <option>Low</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='ves'
                      >
                        Vessel
                      </label>
                      <select
                        className='form-control ves'
                        data-toggle='select2'
                        id='ves'
                        name='ActionItem.VesselId'
                      >
                        <option value=''>-- Select Vessel --</option>
                        <option value='93607800-3079-ec11-aa80-06de4ad97f56'>
                          Seabed Survey Vessel 1
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='sd'
                      >
                        Start Date <span className='text-danger ms-1'>*</span>
                      </label>

                      <input
                        value=''
                        className='form-control sd flatpickr-input'
                        data-toggle='flatpicker'
                        id='sd'
                        name='ActionItem.DateIssued'
                        required
                        type='hidden'
                      />
                      <input
                        className='form-control sd form-control input'
                        placeholder=''
                        required
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='sd'
                      >
                        End Date <span className='text-danger ms-1'>*</span>
                      </label>

                      <input
                        value=''
                        className='form-control ed flatpickr-input'
                        data-toggle='flatpicker'
                        id='ed'
                        name='ActionItem.TargetDate'
                        required
                        type='hidden'
                      />
                      <input
                        className='form-control ed form-control input'
                        placeholder=''
                        required
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                  <div className='col-xl-2 col-xs-12'>
                    <div className='form-group'>
                      <label
                        className='d-flex align-items-center fs-5 fw-semibold mt-5 mb-2'
                        htmlFor='sd'
                      >
                        Date Completed
                      </label>
                      <input
                        value=''
                        className='form-control dc flatpickr-input'
                        data-toggle='flatpicker'
                        id='dc'
                        name='ActionItem.DateCompleted'
                        type='hidden'
                      />
                      <input
                        className='form-control dc form-control input'
                        placeholder=''
                        tabIndex={0}
                        type='text'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='col-md-12 mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header cursor-pointer align-items-center justify-content-between'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Master Grid</h3>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='btn btn-secondary d-flex align-items-center'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen042.svg'
                      className='svg-icon-muted svg-icon-1hx'
                    />
                    <span>Disable Checkboxes</span>
                  </div>
                  <div className='btn btn-danger d-flex align-items-center ms-5'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen040.svg'
                      className='svg-icon-muted svg-icon-1hx'
                    />
                    <span>Clear All Actions</span>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='bg-light-primary rounded p-5 border border-primary'>
                  <div className='row'>
                    <h5 className='text-primary mb-5'>
                      <i>Global Controls</i>
                    </h5>
                  </div>
                  <div className='row'>
                    <div className='col-3'>
                      <div className='form-group'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <label
                            className='d-flex align-items-center fs-5 fw-semibold'
                            htmlFor='sd'
                          >
                            Person Responsible <span className='text-danger ms-1'>*</span>
                          </label>
                          <div
                            className='badge badge-primary cursor-pointer'
                            onClick={() => setIsAuthModalOpen(true)}
                          >
                            Apply
                          </div>
                        </div>

                        <select className='form-control bg-white' required>
                          <option value=''>Select Person</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='form-group'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <label
                            className='d-flex align-items-center fs-5 fw-semibold'
                            htmlFor='sd'
                          >
                            Priority <span className='text-danger ms-1'>*</span>
                          </label>
                          <div
                            className='badge badge-primary cursor-pointer'
                            onClick={() => setIsAuthModalOpen(true)}
                          >
                            Apply{' '}
                          </div>
                        </div>

                        <select className='form-control bg-white' required>
                          <option value=''>Select Priority</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='form-group'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <label
                            className='d-flex align-items-center fs-5 fw-semibold'
                            htmlFor='sd'
                          >
                            Vessel <span className='text-danger ms-1'>*</span>
                          </label>
                          <div
                            className='badge badge-primary cursor-pointer'
                            onClick={() => setIsAuthModalOpen(true)}
                          >
                            Apply
                          </div>
                        </div>

                        <select className='form-control bg-white' required>
                          <option value=''>Select Vessel</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='form-group'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <label
                            className='d-flex align-items-center fs-5 fw-semibold'
                            htmlFor='sd'
                          >
                            Start Date<span className='text-danger ms-1'>*</span>
                          </label>
                          <div
                            className='badge badge-primary cursor-pointer'
                            onClick={() => setIsAuthModalOpen(true)}
                          >
                            Apply
                          </div>
                        </div>

                        <input className='form-control bg-white' required />
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className='form-group'>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <label
                            className='d-flex align-items-center fs-5 fw-semibold'
                            htmlFor='sd'
                          >
                            End Date <span className='text-danger ms-1'>*</span>
                          </label>
                          <div
                            className='badge badge-primary cursor-pointer'
                            onClick={() => setIsAuthModalOpen(true)}
                          >
                            Apply
                          </div>
                        </div>

                        <input className='form-control bg-white' required />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='badge badge-light-primary m-2'>
                    <i>
                      *Global controls can be applied on Selected Actions checked using checkboxes
                      OR Click Disable Checkboxes to apply on all actions
                    </i>
                  </div>

                  <div className='badge badge-light-danger m-2'>
                    <i>
                      **Both Date Completed and Action Taken are needed to mark an action Complete{' '}
                    </i>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-center py-6 px-9'>
                <button type='submit' className='btn btn-primary mx-2'>
                  <i className='bi bi-check2-circle'></i> Submit
                </button>
                <button type='submit' className='btn btn-light mx-2'>
                  <i className='bi bi-x'></i>Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        dialogClassName='modal-dialog modal-dialog-centered mw-500px'
        show={isAuthModalOpen}
        handleClose={() => setIsAuthModalOpen(false)}
      >
        <div className='my-10 mx-5'>
          <div className='text-center'>
            <h5 className='fw-normal'>
              This selection will be applied on <b className='text-danger'>All/Selected</b> actions
              in the Master Grid
            </h5>
            <p className='mt-10 text-danger fs-2 fw-bold'>Are you sure?</p>
          </div>
        </div>
        <div className='d-flex justify-content-around'>
          <div className='btn btn-light-primary flex-fill me-1 ms-2 my-2'>Yes</div>
          <div
            className='btn btn-light-danger flex-fill  me-2 ms-1 my-2'
            onClick={() => setIsAuthModalOpen(false)}
          >
            No
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BulkImport
