import React, {useState} from 'react'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {KTSVG} from '../../../../_metronic/helpers'

export interface Todo {
  id: number
  title: string
}

const Step2: React.FC = () => {
  const [availableAuth, setAvailableAuth] = useState<Array<Todo>>([
    {id: 1, title: 'Certifying Authority Concurrence'},
    {id: 2, title: 'Transport Canada Approval'},
    {id: 3, title: 'Applicant'},
    {id: 4, title: 'CNSOPB Decision'},
    {id: 5, title: 'Workplace Committee'},
    {id: 6, title: 'Operators Concurrence'},
    {id: 7, title: 'CNLOPB Decision'},
  ])
  const [SelectedAuth, setSelectedAuth] = useState<Array<Todo>>([])

  const onDragEnd = (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    let add
    let available = availableAuth
    let selected = SelectedAuth
    // Source Logic
    if (source.droppableId === 'AvailableList') {
      add = available[source.index]
      available.splice(source.index, 1)
    } else {
      add = selected[source.index]
      selected.splice(source.index, 1)
    }

    // Destination Logic
    if (destination.droppableId === 'AvailableList') {
      available.splice(destination.index, 0, add)
    } else {
      selected.splice(destination.index, 0, add)
    }

    setSelectedAuth(selected)
    setAvailableAuth(available)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='App'>
        <h5 className='text-center mb-10'>
          Move authorities to the right container in the sequence
        </h5>
        <div className='row'>
          <div className='col-md-6'>
            <Droppable droppableId='AvailableList'>
              {(provided, snapshot) => (
                <div
                  className={`card card-body bg-secondary ${
                    snapshot.isDraggingOver ? 'dragactive' : ''
                  }`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <span className='fs-4 fw-bold mb-5'>Available Authorities</span>
                  {availableAuth?.map((todo, index) => (
                    <Draggable draggableId={todo.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={`card card-body border mb-4 ${
                            snapshot.isDragging ? 'drag' : ''
                          }`}
                        >
                          <div className='d-flex justify-content-between align-items-center'>
                            <b>{todo.title}</b>
                            <span>
                              <KTSVG
                                path='/media/icons/duotune/general/gen053.svg'
                                className='svg-icon-muted svg-icon-1hx'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className='col-md-6'>
            <Droppable droppableId='SelectedList'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`card card-body bg-secondary ${
                    snapshot.isDraggingOver ? 'dragcomplete' : 'remove'
                  }`}
                >
                  <span className='fs-4 fw-bold mb-5'>Selected Authorities</span>
                  {SelectedAuth?.map((todo, index) => (
                    <Draggable draggableId={todo.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={`card card-body border mb-4 ${
                            snapshot.isDragging ? 'drag' : ''
                          }`}
                        >
                          <div className='d-flex justify-content-between align-items-center'>
                            <b>{todo.title}</b>
                            <span>
                              <KTSVG
                                path='/media/icons/duotune/general/gen053.svg'
                                className='svg-icon-muted svg-icon-1hx'
                              />
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    </DragDropContext>
  )
}

export {Step2}
